import { useNavigate } from "react-router-dom";

const NotFoundPage = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate("/");
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-pink-500 to-indigo-500 text-white">
      <div className="text-center">
        <h1 className="text-9xl font-bold animate__animated animate__shakeX mb-4">
          404
        </h1>
        <p className="text-2xl font-semibold mb-6">
          Oops! Something went terribly wrong.
        </p>
        <p className="text-xl mb-6">
          We can't seem to find the page you're looking for. Don't worry, we can
          get you back on track!
        </p>
        <button
          onClick={handleGoHome}
          className="px-6 py-3 bg-yellow-500 text-black rounded-lg font-bold text-lg transition transform hover:scale-105 hover:bg-yellow-400 active:scale-95 focus:outline-none"
        >
          Return to Home
        </button>
      </div>
    </div>
  );
};

export default NotFoundPage;
