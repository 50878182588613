import { useMutation } from "react-query";
// import { useNavigate } from 'react-router-dom';
import { axiosInstance } from "../../../../utils/axiosInstance";
import { message } from "antd";
import { useNavigate } from "react-router-dom";

interface Props {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  password: string;
}

export const useRegisterStudent = (payload: Props) => {
  const navigate = useNavigate();

  return useMutation(
    () =>
      axiosInstance.post("auth/register", payload).then((res: any) => res.data),
    {
      onSuccess: async (data: any) => {
        // localStorage.setItem("W2ST_STUDENT_TOKEN", data?.data?.accessToken);
        navigate("/student/signin");
      },
      onError: (error: any) => {
        message.error(error?.response?.data?.message);
      },
    }
  );
};
