import React from 'react';

type SessionCardProps = {
  image: string;
  name: string;
  subject: string;
  sessionType: string;
  duration: string;
};

const SessionCard = ({
  image,
  name,
  subject,
  sessionType,
  duration,
}: SessionCardProps) => {
  return (
    <div className="flex gap-3 mb-6">
      <img src={image} alt="" className="w-[50px] h-[50px]" />
      <div className="flex-1">
        <p className="text-gray900 W2STMedium">{name}</p>
        <span className="text-gray500">
          {subject} | {sessionType} session | {duration} mins
        </span>
      </div>
      <span>
        <button className="p-3 border-[1px] border-gray300 rounded-md text-sm">
          Leave a review
        </button>
      </span>
    </div>
  );
};

export default SessionCard;
