import React from "react";
import Moment from "react-moment";

const ExperienceCard = ({ title, company, startDate, endDate }: any) => {
  return (
    <div className="mb-[30px]">
      <p className="text-primary900 mb-1 font-extrabold">{title}</p>
      <p className="text-primary900 mb-1">{company}</p>
      <p className="font-bold text-gray500">
        <Moment format="YYYY" interval={0}>
          {startDate}
        </Moment>
        {" - "}
        {endDate === null ? (
          "Present"
        ) : (
          <Moment format="YYYY" interval={0}>
            {endDate}
          </Moment>
        )}
      </p>
      {/* <div className="flex gap-5 mt-3">
        <span className="flex gap-2 items-center text-primary600 cursor-pointer">
          <AiOutlineEdit className="text-2xl" />
          <span>Edit</span>
        </span>
        <span className="flex gap-2 items-center text-error500 cursor-pointer">
          <FaRegTrashCan />
          <span>Delete</span>
        </span>
      </div> */}
    </div>
  );
};

export default ExperienceCard;
