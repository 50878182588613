import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../../../../utils/axiosInstance";
import { message } from "antd";

interface Props {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  password: string;
}

export const useRegisterTutorMutation = () => {
  const navigate = useNavigate();

  return useMutation(
    (payload: Props) =>
      axiosInstance
        .post("tutors/register", payload)
        .then((res: any) => res.data),
    {
      onSuccess: async (data: any) => {
        // localStorage.setItem("W2ST_TUTOR_TOKEN", data?.data?.accessToken);
        message.success(data?.message);
        navigate("/tutor/signin");
      },
      onError: (error: any) => {
        message.error(error?.response?.data?.message);
      },
    }
  );
};
