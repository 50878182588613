import { useMutation } from "react-query";
import { axiosInstance } from "../../../../utils/axiosInstance";
import { message } from "antd";

export const useLoginAdmin = () => {
  return useMutation(
    (email: any) =>
      axiosInstance
        .post("/admins/login", { email: email })
        .then((res: any) => res.data),
    {
      onSuccess: async (data: any) => {},
      onError: (error: any) => {
        message.error(
          error?.response?.data?.message || "Login failed. Please try again."
        );
      },
    }
  );
};
