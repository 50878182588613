import { useMutation } from "react-query";
import { axiosInstance } from "../../../../utils/axiosInstance";
import { message } from "antd";
import { useNavigate } from "react-router-dom";

export const useCreateAvailability = () => {
  const token = localStorage.getItem("W2ST_TUTOR_TOKEN");
  const navigate = useNavigate();

  return useMutation(
    (payload: any) =>
      axiosInstance
        .patch("tutors/availability", payload, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res: any) => res.data),
    {
      onSuccess: async (data: any) => {
        message.success(data?.message);
        navigate("/tutor/overview");
        // queryClient.invalidateQueries("gettutor");
        // navigate("/tutor/overview", { replace: true });
      },
      onError: (error: any) => {
        message.error(
          error?.response?.data?.message 
        );
      },
    }
  );
};
