import { QueryClient } from 'react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnReconnect: 'always',
      refetchOnWindowFocus: 'always',
      refetchOnMount: 'always',
    },
  },
});