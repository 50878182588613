import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const location = useLocation();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    if (isMenuOpen === true) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [isMenuOpen]);

  const Logo = require("../../../assets/images/logo.png");

  return (
    <nav className="p-6">
      <div className="mx-auto flex justify-between items-center">
        <section className="flex gap-4">
          <Link to="/">
            <img
              src={Logo}
              alt="Logo of Way2SuccessTutors"
              // className="h-[20px]"
            />
          </Link>
          <section className="md:flex gap-4 hidden W2STLight items-center text-black text-tmd ml-10">
            <Link
              to="/"
              className={
                location.pathname === "/"
                  ? "underline underline-offset-[10px] decoration-purple800 decoration-2"
                  : ""
              }
            >
              For Student
            </Link>
            <Link
              to="/tutors"
              className={
                location.pathname.includes("/tutors")
                  ? "underline underline-offset-[10px] decoration-purple800 decoration-2"
                  : ""
              }
            >
              For Tutors
            </Link>
            {/* <Link href="/#parents">For Parents</Link> */}
            <Link
              to="/about"
              className={
                location.pathname.includes("/about")
                  ? "underline underline-offset-[10px] decoration-purple800 decoration-2"
                  : ""
              }
            >
              About Us
            </Link>
            <Link to="/#faq">FAQs</Link>
          </section>
        </section>
        <section className="md:flex gap-4 hidden">
          <button className="w2st-button text-tmd">Sign up</button>

          <Link to="/">
            <button className="w2st-light-button text-tmd">Log In</button>
          </Link>
        </section>
        <div className="md:hidden font-bold">
          <button
            onClick={toggleMenu}
            className="text-black hover:text-gray-300"
          >
            {isMenuOpen ? (
              <span className="flex items-center gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18 18 6M6 6l12 12"
                  />
                </svg>
                Close
              </span>
            ) : (
              <span className="flex items-center gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5"
                  />
                </svg>
                MENU
              </span>
            )}
          </button>
        </div>
      </div>
      {isMenuOpen && (
        <div
          className="md:hidden absolute top-16 right-0 bg-white w-[100vw] p-4 z-10  h-[100vh]"
          onClick={closeMenu}
        >
          <Link to="/" className="block py-2 px-4 text-black">
            For Students
          </Link>
          <Link to="/tutors" className="block py-2 px-4 text-black">
            For Tutors
          </Link>
          <Link to="/about" className="block py-2 px-4 text-black">
            About us
          </Link>
          <Link to="/#faq" className="block py-2 px-4 text-black">
            FAQ
          </Link>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
