import React from "react";
import { AiOutlineEdit, AiOutlinePlus } from "react-icons/ai";
import Card from "../../../../components/Card";

const Options = (data: any) => {
  return (
    <div>
      <Card width="12" style={{ marginTop: "10px" }}>
        <div className="flex justify-between items-center">
          <h1 className="text-xl W2STBold text-gray700 mb-3">
            Subject expertise
          </h1>
        </div>
        <div>
          <span className="blob font-bold">
            {data?.data?.data?.areaOfExpertise[0]}
          </span>
        </div>
      </Card>
      <Card width="12" style={{ marginTop: "10px" }}>
        <div className="flex justify-between items-center">
          <h1 className="text-xl W2STMedium text-gray700 mb-3">Language</h1>
          {/* <span className="text-primary600 flex gap-3 items-center cursor-pointer">
            <AiOutlinePlus /> Add
          </span> */}
        </div>
        <div>
          <span className="blob">
            <span className="font-bold">
              {data?.data?.data?.preferredLanguage}
            </span>
          </span>
        </div>
      </Card>
      {/* <Card width="12" style={{ marginTop: "10px" }}>
        <div className="flex justify-between items-center">
          <h1 className="text-xl font-medium text-gray700 mb-3">Location</h1>
          <span className="text-primary600 flex gap-3 items-center cursor-pointer">
            <AiOutlineEdit className="text-2xl" />
            Edit
          </span>
        </div>
        <span className="blob">
          <span className="font-bold">Spain</span> |{" "}
          <span className="text-gray500">GMT - 11</span>
        </span>
      </Card> */}
    </div>
  );
};

export default Options;
