import React, { useState } from "react";

import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

const TutorFAQ = () => {
  const Model = require("../../../assets/images/model.webp");

  const [active, setActive] = useState("search");

  const searchFaq = [
    {
      id: 1,
      header: "What Is Way 2 Success Tutors?",
      answer: (
        <div>
          Way 2 Success Tutors is an online platform that connects tutors with
          students (and parents). Our tutoring service makes it easier for you
          to get tutoring jobs that fit your busy schedules.
          <div className="mt-3 W2STBold">Top 3 reasons to become a tutor:</div>
          <div> - Get tutoring jobs locally and remotely. </div>
          <div> - Flexible tutoring hours and personalized schedules.</div>
          <div> - Expand your reach.</div>
          <div className="mt-3">
            These benefits enable you to boost your tutoring business or earn
            some extra cash in your spare time.
          </div>
        </div>
      ),
    },
    {
      id: 2,
      header: "What Qualifications Should Tutors Have?",
      answer: (
        <div>
          <div>
            Our qualification requirements vary from course to course. We accept
            tutors with bachelor’s degree for teaching school courses
            (elementary to high school). Tutors applying for college courses
            must have a master’s degree or doctorate.{" "}
          </div>
          <div className="mt-3">
            All tutors must have at least 1 year teaching/tutoring experience.
          </div>
          <div className="mt-3">
            <span className="W2STBold">Disclaimer:</span>{" "}
            <i>
              We conduct a test/interview to ensure that you have the confidence
              and communication skills required for this tutoring job.
            </i>
          </div>
        </div>
      ),
    },
    {
      id: 3,
      header: "How to Sign up for Your Tutoring Service?",
      answer: (
        <div>
          <div>1. Select the Sign Up button.</div>
          <div>2. Fill the online registration form.</div>
          <div>3. Take an interview/demo.</div>
          <div>4. Get full access to our platform </div>

          <div className="mt-3">
            That’s it. You are ready to kick-start your tutoring career
          </div>

          <div className="mt-3">
            All you have to do is wait for students and parents. Make sure to
            check your email to get notified whenever you receive a message.{" "}
          </div>

          <div className="mt-3">
            <span className="W2STBold">Disclaimer:</span>{" "}
            <i>
              We have the right to contact third parties for verification. We
              also want 2-3 referrals from students or co-workers. These
              verification steps increase your credibility and make it easier
              for you to rank higher on the search list.
            </i>
          </div>
        </div>
      ),
    },
    {
      id: 4,
      header: "How to Create a Tutor Profile?",
      answer: (
        <div>
          <div>
            Once your applications get approved, you get directed to your
            dashboard. Remember to fill all the essential spaces when you create
            your profile. Do add a recent photograph, qualification details, and
            teaching methodologies when you write your bio.
          </div>
          <div className="mt-3">
            <span className="W2STBold">Pro-tip:</span>{" "}
            <i>
              Keep the information short and sweet to prevent learners from
              becoming overwhelmed.
            </i>
          </div>
        </div>
      ),
    },
    {
      id: 5,
      header: "Do Tutors Need Any Training for Online Tutoring Jobs? ",
      answer: (
        <div>
          <div>
            As long as you have good Wi-Fi and general knowledge about online
            classes, you are good to go. We do recommend testing the digital
            classroom settings and tools beforehand. That way, you will not
            struggle to make sure things work during the live session.{" "}
          </div>
          <div className="mt-3">
            If you need further assistance or training, do get in touch with our
            support team. They will answer all your questions to ensure that
            nothing goes amiss during class.{" "}
          </div>
        </div>
      ),
    },
  ];

  const processFaq = [
    {
      id: 6,
      header: "How Do I Find Tutoring Jobs? ",
      answer: (
        <div>
          <div>
            Once you create a tutor profile, your name will pop up during search
            results. Interested students (and parents) can check your profile
            and send queries to obtain more information.{" "}
          </div>
          <div className="mt-3">
            Respond to their messages to schedule a demo session. Once you get
            selected, you can arrange a tutoring schedule that works for you
            (and them).{" "}
          </div>
        </div>
      ),
    },
    {
      id: 7,
      header: "How Often Should I Tutor? ",
      answer: (
        <div>
          Our private tutors have the flexibility to teach whenever they want
          and as much as they want. You can register as a part-time tutor and
          work once a week. Or use this as a full-time job by scheduling
          multiple classes throughout the day/week.
          <br />
          It all depends on how much time you have to spare and what tutoring
          goals you set for yourself.
        </div>
      ),
    },
    {
      id: 8,
      header: "How Do I Prepare for these Tutoring Sessions?",
      answer: (
        <div>
          Like any tutoring job, this one requires a proper lesson plan and
          supplementary teaching resources. We request tutors to set learning
          goals and stay up-to-date with the learners’ schoolwork (or college
          curriculum). Having these details will ensure that your lessons are
          compatible with what the students learn in the class.
          <br />
          Online tutors must have a strong internet connection, webcam,
          speaker/headphones, and microphone. Otherwise, they risk facing
          technical problems during the live sessions. We also recommend testing
          the digital tools and classroom settings before the class. That way,
          you can contact our customer support team if there are any issues.
        </div>
      ),
    },
    {
      id: 9,
      header: "Can Tutors Reschedule Classes? ",
      answer: (
        <div>
          Yes, you can.
          <br />
          If there is an emergency or have other commitments, you can change the
          date (or time) of an upcoming tutoring session. We only have one
          condition. We request tutors to reschedule their sessions 24 hours in
          advance. That way, the learner gets notified beforehand and does not
          face any inconvenience.
        </div>
      ),
    },
    {
      id: 10,
      header: "How Do Tutors Get Paid? ",
      answer: (
        <div>
          We pay tutors via online bank transactions, checks, or PayPal,
          depending on the chosen payment method. Having different payment
          options leads to convenient and secure transactions. Your payment will
          arrive (at the end of the month/weekly session).
          <br />
          Please note that all student payments get addressed to
          Way2SuccessTutors. After that, we send the designated amount to
          registered tutors. If you ask parents (or students) for cash payments
          or direct checks, we will terminate your tutor account.
          <div>
            <span className="W2STBold">Disclaimer: </span>
            <i>
              Way2SucceessTutors would not be responsible for any transaction(s)
              that happens outside out platform
            </i>
          </div>
        </div>
      ),
    },
  ];

  const activetab =
    "flex-1 text-center text-primary700 bg-primary50 rounded-full p-3";
  const inActiveTab =
    "flex-1 text-center bg-white rounded-full text-gray900 p-3";

  return (
    <section className="py-10" id="faq">
      <div className="text-center text-sm uppercase text-gray500 mt-[141px] mb-[12px] md:mb-[24px]">
        FREQUENT QUESTIONS
      </div>
      <center className="text-dsm md:text-dlg text-gray700 W2STMedium mb-[12px] md:mb-[24px]">
        You have questions and we
        <br className="hidden md:block" /> have answers
      </center>
      <div className="text-gray500 text-tmd md:text-txl text-center mb-[40px] md:mb-[80px] px-5">
        Experience the ease of connecting with highly qualified{" "}
        <br className="md:block hidden" />
        educators for both parents and students.
      </div>
      <div className="container mx-auto mt-4">
        <div className="md:flex gap-[40px]">
          <div className="md:w-6/12 w-full">
            <div className="continer mx-auto md:w-[450px] w-full bg-white rounded-full border-[1px] border-primary200 p-1">
              <span className="flex">
                <span
                  className={`transition ease-in-out duration-300 cursor-pointer ${
                    active === "search" ? activetab : inActiveTab
                  }`}
                  onClick={() => setActive("search")}
                >
                  Search
                </span>
                <span
                  className={`transition ease-in-out duration-300 cursor-pointer ${
                    active === "process" ? activetab : inActiveTab
                  }`}
                  onClick={() => setActive("process")}
                >
                  Our Process
                </span>
              </span>
            </div>
            <div className="mt-10 md:container mx-aut0 px-0">
              {active === "search" ? (
                <Accordion>
                  {searchFaq.map(({ id, header, answer }) => (
                    <AccordionItem key={id}>
                      <AccordionItemHeading>
                        <AccordionItemButton>{header}</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <div className="text-gray700">{answer}</div>
                      </AccordionItemPanel>
                    </AccordionItem>
                  ))}
                </Accordion>
              ) : (
                <Accordion>
                  {processFaq.map(({ id, header, answer }) => (
                    <AccordionItem key={id}>
                      <AccordionItemHeading>
                        <AccordionItemButton>{header}</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <div className="text-gray700">{answer}</div>
                      </AccordionItemPanel>
                    </AccordionItem>
                  ))}
                </Accordion>
              )}
            </div>
          </div>
          <div className="w-6/12">
            <img
              src={Model}
              alt=""
              className="md:block hidden mx-auto h-[500px] object-contain"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default TutorFAQ;
