import React from "react";

import ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import "./index.css";
import { QueryClientProvider } from "react-query";
import { queryClient } from "./services/queryClient";
// STUDENT ROUTES
import StudentSignUp from "./pages/Students/Auth/SignUp/StudentSignUp";
import StudentSignin from "./pages/Students/Auth/SignIn/StudentSignin";
import StudentOverview from "./pages/Students/Overview/StudentOverview";
import StudentTutors from "./pages/Students/Tutors/StudentTutors";
import StudentTutorDisplayPage from "./pages/Students/Tutors/StudentTutorDisplayPage";
import StudentBookings from "./pages/Students/Bookings/StudentBookings";
import StudentSettings from "./pages/Students/Settings/StudentSettings";
// TUTOR ROUTES
import TutorSignUp from "./pages/Tutors/Auth/SignUp/TutorSignUp";
import TutorSignin from "./pages/Tutors/Auth/SignIn/TutorSignin";
import TutorOverview from "./pages/Tutors/Overview/TutorOverview";
import TutorBookings from "./pages/Tutors/Bookings/TutorBookings";
import TutorAvailability from "./pages/Tutors/Availability/TutorAvailability";
import TutorProfile from "./pages/Tutors/Profile/TutorProfile";
import TutorSettings from "./pages/Tutors/Settings/TutorSettings";
import Onboarding from "./pages/Tutors/Onboarding/Onboarding";
// ADMIN ROUTES
import Students from "./pages/Admin/Students";
import StudentDetails from "./pages/Admin/Students/StudentDetails";
import AdminTutor from "./pages/Admin/Tutors";
import AdminLogin from "./pages/Admin/Auth/AdminLogin";
import TutorDetails from "./pages/Admin/Tutors/TutorDetails";
import NotFoundPage from "./pages/NotFoundPage";
import Home from "./pages/Public/Home/Home";
import About from "./pages/Public/About/About";
import Tutors from "./pages/Public/Tutors/Tutors";
import Subjects from "./pages/Admin/Subjects";
import { ConfigProvider } from "antd";
// import { loadStripe } from "@stripe/stripe-js";
// import { Elements } from "@stripe/react-stripe-js";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/tutors",
    element: <Tutors />,
  },
  {
    path: "/student/signup",
    element: <StudentSignUp />,
  },
  {
    path: "/student/signin",
    element: <StudentSignin />,
  },
  {
    path: "/student/overview",
    element: <StudentOverview />,
  },
  {
    path: "/student/bookings",
    element: <StudentBookings />,
  },
  {
    path: "/student/settings",
    element: <StudentSettings />,
  },
  {
    path: "/student/tutors",
    element: <StudentTutors />,
  },
  {
    path: "/student/tutors/:id",
    element: <StudentTutorDisplayPage />,
  },
  {
    path: "/tutor/signup",
    element: <TutorSignUp />,
  },
  {
    path: "/tutor/signin",
    element: <TutorSignin />,
  },
  {
    path: "/tutor/overview",
    element: <TutorOverview />,
  },
  {
    path: "/tutor/bookings",
    element: <TutorBookings />,
  },
  {
    path: "/tutor/availability",
    element: <TutorAvailability />,
  },
  {
    path: "/tutor/profile",
    element: <TutorProfile />,
  },
  {
    path: "/tutor/settings",
    element: <TutorSettings />,
  },
  {
    path: "/tutor/onboarding",
    element: <Onboarding />,
  },
  { path: "/admin/login", element: <AdminLogin /> },
  { path: "/admin/overview", element: <AdminTutor /> },
  { path: "/admin/tutors", element: <AdminTutor /> },
  { path: "/admin/tutors/:id", element: <TutorDetails /> },
  { path: "/admin/students", element: <Students /> },
  { path: "/admin/students/:id", element: <StudentDetails /> },
  { path: "/admin/payments", element: <AdminTutor /> },
  { path: "/admin/subjects", element: <Subjects /> },
  { path: "*", element: <NotFoundPage /> },
]);

// const stripePromise = loadStripe("pk_test_6pRNASCoBOKtIshFeQd4XMUh");

const options = {
  mode: "payment",
  amount: 1099,
  currency: "usd",
  // Fully customizable with appearance API.
  // appearance: {
  /*...*/
  // },
};

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#6938EF",
          },
        }}
      >
        {/* <Elements stripe={stripePromise} options={options}> */}
        <RouterProvider router={router} />
        {/* </Elements> */}
      </ConfigProvider>
    </QueryClientProvider>
  </React.StrictMode>
);
