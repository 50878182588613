import React, { ReactNode } from "react";
import { FiMail } from "react-icons/fi";
import { Link } from "react-router-dom";

type Props = {
  children: ReactNode;
  heading: string;
  desc: string;
};

const AuthLayout = ({ children, heading, desc }: Props) => {
  const Logo = require("../assets/images/logo.png");
  const Banner = require("../assets/images/group.png");
  const Stars = require("../assets/images/stars.png");

  return (
    <div className="md:flex block">
      <div className="md:w-[50%] w-full h-screen">
        <div className="h-[100vh] flex flex-col">
          <div className="p-4 flex-none">
            <Link to="/">
              <img
                src={Logo}
                alt="Way2SuccessTutors logo"
                style={{ height: "40px" }}
              />
            </Link>
          </div>
          <div className="grow overflow-y-scroll h-[90vh] pt-10 pb-20 px-4">
            {children}
          </div>
          <div className="flex-none w-[100%] p-4 bottom-0 flex justify-between text-[#667085]">
            <span className="">
              <Link to="/terms">Term of use</Link>
            </span>
            <span>
              <Link
                to="mailto:help@way2sucess.com"
                className="flex gap-2 items-center"
                target="_blank"
              >
                <FiMail size={20} />
                help@way2sucess.com
              </Link>
            </span>
          </div>
        </div>
      </div>
      <div className="w-[50%] h-screen bg-black text-white bg-gradient-to-tr from-[#0F0068] to-[#9747FF] px-20 md:block hidden">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <div>
            <div className="mb-5">
              <img src={Stars} alt="" className="mb-2" />
              {/* <h3 className="text-gray900 text-txs font-bold mb-3"> */}

              <h1 className="text-dsm mb-5 font-semibold">{heading}</h1>
              <p className="text-[#E4E7EC] w-[80%] leading-loose">{desc}</p>
            </div>
            <div className="flex gap-5 items-center">
              <img src={Banner} alt="avatar" className="h-[50px]" />
              <span>Join 5,000+ Tutors</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
