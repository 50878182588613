import React from "react";
import { LuCalendar, LuClock4 } from "react-icons/lu";
import { FaRegStar } from "react-icons/fa";
import { BsArrowRightShort } from "react-icons/bs";
import StudentDashboard from "../../../layout/StudentDashboard";
import { Link } from "react-router-dom";
import { useGetTutorsQuery } from "../../../hooks/api/Students/Tutors/useGetTutorsQuery";
import { useGetStudentQuery } from "../../../hooks/api/Students/Auth/useGetStudentQuery";

const StudentOverview = () => {
  const { data: student } = useGetStudentQuery();
  // const { data: tutors, isLoading } = useGetTutorsQuery({});

  return (
    <StudentDashboard>
      <div className="container px-6">
        <p className="text-txs text-gray900 W2STBold md:mt-0 mt-3">
          Welcome{student?.data?.firstName && ", " + student?.data?.firstName}
        </p>
        <div className="pb-6 border-b-[1px] border-b-gray300 text-tmd">
          <span className="text-gray600">No upcoming sessions, </span>
          <Link
            to="/student/tutors"
            className="text-primary500 underline underline-offset-2 cursor-pointer"
          >
            let's find you a tutor
          </Link>
        </div>
        <div className="md:flex grid gap-4 md:mt-10 mt-3 mb-10">
          <div className="rounded-lg p-3 border-[1px] bordeer-gray100  md:w-4/12 w-full bg-white">
            <div className="flex gap-4 items-center text-primary600 mb-2">
              <span className="rounded-full bg-primary50 p-4 text-[30px]">
                <LuCalendar />
              </span>
              <span className="text-gray900 W2STMedium text-tmd">
                Sessions booked
              </span>
            </div>
            <p className="text-dmd text-gray900 W2STBold ml-2">20</p>
          </div>
          <div className="rounded-lg p-3 border-[1px] bordeer-gray100  md:w-4/12 w-full bg-white">
            <div className="flex gap-4 items-center text-bluelight600 mb-2">
              <span className="rounded-full bg-bluelight50 p-4 text-[30px]">
                <LuClock4 />
              </span>
              <span className="text-gray900 W2STMedium text-tmd">
                Total session time
              </span>
            </div>
            <p className="text-dmd text-gray900 W2STBold ml-2">1,210 Mins</p>
          </div>
          <div className="rounded-lg p-3 border-[1px] bordeer-gray100  md:w-4/12 w-full bg-white">
            <div className="flex gap-4 items-center text-warning600 mb-2">
              <span className="rounded-full bg-warning50 p-4 text-[30px]">
                <FaRegStar />
              </span>
              <span className="text-gray900 W2STMedium text-tmd">
                Average rating
              </span>
            </div>
            <p className="text-dmd text-gray900 W2STBold ml-2">4.5/5</p>
          </div>
        </div>
        <p className="text-txl text-gray900 W2STBold">Suggested Tutors</p>
        <div className="pb-6 border-b-[1px] border-b-gray300 flex justify-between">
          <div>
            <span className="text-gray600">
              The suggestions are based on your location{" "}
            </span>
            {/* <span className="text-primary500 underline underline-offset-2">
              Africa/Lagos
            </span> */}
          </div>
          <Link
            to="/student/tutors"
            className="flex gap-3 text-gray500 items-center"
          >
            See more <BsArrowRightShort />
          </Link>
        </div>
        <div className="mb-[100px]">
          {/* {JSON.stringify(tutors)} */}
          {/* {isLoading && <h3 className="text-black">LOADING...</h3>} */}
          {/* <div className="md:grid md:grid-cols-4 gap-3 mt-5">
          {tutors &&
            tutors.tutors.length > 0 &&
            !isLoading &&
            tutors.tutors
              .slice(0, 4)
              .map(
                ({
                  firstname,
                  lastname,
                  _id,
                  professionalTitle,
                  photo,
                }: {
                  firstname: string;
                  lastname: string;
                  _id: string;
                  professionalTitle: string;
                  photo: string;
                }) => (
                  <div key={_id}>
                    <TutorCard
                      image={image}
                      name={firstname + ' ' + lastname}
                      title={professionalTitle}
                      sessions={10}
                      reviews={40}
                      availability="Virtual"
                      rate={500}
                    />
                  </div>
                )
              )}
        </div> */}
        </div>
      </div>
    </StudentDashboard>
  );
};

export default StudentOverview;
