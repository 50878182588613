import { message } from "antd";
import { FiPhone, FiMail, FiCopy } from "react-icons/fi";
import { GoDotFill } from "react-icons/go";
import { Link, useLocation } from "react-router-dom";
import { useGetTutorQuery } from "../../hooks/api/Tutors/Auth/useGetTutorQuery";

type Props = {
  payload: any;
};

const ProfileCard = ({ payload }: Props) => {
  const Twitter = require("../../assets/images/twitter.png");
  const LinkedIn = require("../../assets/images/linkedin.png");
  const Web = require("../../assets/images/web.png");
  // const Avatar = require('../../assets/avatar.png');

  const location = useLocation();

  const { data } = useGetTutorQuery();

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(
        `https://w2st.net/tutors/${data?.data?.id}`
      );
      message.success("Link Copied");
    } catch (err) {
      console.error("Error copying to clipboard:", err);
    }
  };

  return (
    <div className="p-6 bg-white rounded-lg mb-3">
      <div className="grid place-content-center">
        <img
          src={data?.data?.profilePicture ? data?.data?.profilePicture : ""}
          alt="tutor avatar"
          className="bg-gray200 rounded-full h-[180px] w-[180px]"
        />
      </div>

      <div className="grid place-content-center mt-4">
        <span className="flex items-center gap-2 text-indigo700 p-2 rounded-full bg-indigo50 text-xs">
          <GoDotFill /> Available for immediate booking
        </span>
      </div>
      <div className="w-[50%] mx-auto flex justify-between mt-3">
        <a
          href="http://twitter.com"
          target="_blank"
          className="p-1 rounded-full cursor-pointer"
          rel="noreferrer"
        >
          <img src={Twitter} alt="" />
        </a>
        <a
          href="http://twitter.com"
          target="_blank"
          className="p-1 rounded-full cursor-pointer"
          rel="noreferrer"
        >
          <img src={LinkedIn} alt="" />
        </a>
        <a
          href="http://twitter.com"
          target="_blank"
          className="p-1 rounded-full cursor-pointer"
          rel="noreferrer"
        >
          <img src={Web} alt="" />
        </a>
      </div>

      <div className="flex items-center gap-2 text-gray700 text-sm mt-3">
        <FiMail className="text-gray700" />
        <span>{data?.data?.email}</span>
      </div>
      <div className="flex items-center gap-2 text-gray700 mt-3 text-sm">
        <FiPhone className="text-gray700" />
        <span>{data?.data?.phoneNumber}</span>
      </div>
      {location.pathname === "/tutor/profile" ? (
        ""
      ) : (
        <div className="mt-3 border-[1px] border-gray300 rounded-md p-2 flex items-center justify-between gap-3">
          <Link
            to={`https://w2st.net/tutors/${data?.data?.id}`}
            target="_blank"
            className="text-gray500 flex-1"
          >
            https://w2st.net/tutors/{data?.data?.id}
          </Link>
          <div className="w-[20px] cursor-pointer" onClick={handleCopyClick}>
            <FiCopy />
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileCard;
