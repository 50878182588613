import { useMutation } from "react-query";
// import { useNavigate } from 'react-router-dom';
import { axiosInstance } from "../../../../utils/axiosInstance";
import { message } from "antd";

interface Props {
  email: string;
  password: string;
}

export const useLoginTutorMutation = () => {
  // const navigate = useNavigate();

  return useMutation(
    (payload: Props) =>
      axiosInstance.post("/tutors/login", payload).then((res: any) => res.data),
    {
      onSuccess: async (data: any) => {
        localStorage.setItem("W2ST_TUTOR_TOKEN", data?.data?.token);
        // navigate('/dashboard');
      },
      onError: (error: any) => {
        message.error(
          error?.response?.data?.message || "Login failed. Please try again."
        );
      },
    }
  );
};
