import { useMutation } from "react-query";
import { axiosInstance } from "../../../../utils/axiosInstance";
import { message } from "antd";
import { queryClient } from "../../../../services/queryClient";
import { useNavigate } from "react-router-dom";

interface Prop {
  availabilityType: string;
  isAvailable: boolean;
}

export const useLevel4Onboarding = () => {
  const token = localStorage.getItem("W2ST_TUTOR_TOKEN");
  const navigate = useNavigate();

  return useMutation(
    (payload: Prop) =>
      axiosInstance
        .post("tutors/onboarding-step-four", payload, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res: any) => res.data),
    {
      onSuccess: async (data: any) => {
        message.success(data?.message);
        queryClient.invalidateQueries("gettutor");
        navigate("/tutor/overview", { replace: true });
      },
      onError: (error: any) => {
        message.error(
          error?.response?.data?.message
        );
      },
    }
  );
};
