import { useMutation } from "react-query";
import { axiosInstance } from "../../../../utils/axiosInstance";
import { message } from "antd";
import { queryClient } from "../../../../services/queryClient";

interface PayloadShape {
  preferredLanguage: string;
  country: string;
  hourlyRate: string;
  profileLinks: {
    facebook: string;
    linkedIn: string;
    x: string;
    personalWebsite: string;
  };
}

export const useLevel2Onboarding = () => {
  const token = localStorage.getItem("W2ST_TUTOR_TOKEN");

  return useMutation(
    (payload: PayloadShape) =>
      axiosInstance
        .post(
          "tutors/onboarding-step-two",
          {
            ...payload,
            hourlyRate: +payload?.hourlyRate,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res: any) => res.data),
    {
      onSuccess: async (data: any) => {
        message.success(data?.message);
        queryClient.invalidateQueries("gettutor");
      },
      onError: (error: any) => {
        message.error(
          error?.response?.data?.message
        );
      },
    }
  );
};
