import React from 'react';

interface CardProps {
  width: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
}

const Card = ({ width, children, style }: CardProps) => {
  return (
    <section
      className={`p-4 bg-white rounded-lg w-${width}/12`}
      style={{ overflowY: 'scroll', ...style }}
    >
      {children}
    </section>
  );
};

export default Card;
