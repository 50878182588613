import { useQuery } from "react-query";
import { axiosInstance } from "../../../../utils/axiosInstance";

interface GetTutorsParams {
  search?: string;
  from?: string;
  to?: string;
  days?: any;
}

export const useGetTutorsQuery = ({
  search, from, to, days
}: GetTutorsParams) => {
  const token = localStorage.getItem("W2ST_STUDENT_TOKEN");

  return useQuery({
    queryKey: [
      "getTutorsonAdmin",
      { search, from, to, days },
    ],
    queryFn: async () => {
      if (!token) throw new Error("Authorization token is missing");

      const response = await axiosInstance.get("users/tutors", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          search,
          from,
          to,
          days
        },
      });

      return response.data;
    },
    onSuccess: (data) => {
      console.log("Success Getting Tutors ==>", data);
    },
    onError: (error: unknown) => {
      console.error("Failed Getting Tutors ==>", error);
    },
    enabled: !!token, 
  });
};
