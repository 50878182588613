import React, { useState } from "react";
import StudentDashboard from "../../../layout/StudentDashboard";
import { useGetTutorsQuery } from "../../../hooks/api/Students/Tutors/useGetTutorsQuery";
import { LuAward } from "react-icons/lu";
import { FiBriefcase } from "react-icons/fi";
import { Drawer, Spin } from "antd";
import AvailabilityBadge from "./Components/AvailabilityBadge";
import TutorBookingForm from "./Components/TutorBookingForm";

const StudentTutors = () => {
  const { isLoading, data } = useGetTutorsQuery({});
  const [id, setId] = useState("");
  const [visible, setVisible] = useState(false);

  const tutorsList = data?.data?.docs;

  if (isLoading && !data) {
    return <StudentDashboard>Loading...</StudentDashboard>;
  }

  return (
    <StudentDashboard>
      <div className="flex gap-2">
        <div className="w-[280px] bg-black">left</div>
        <div className="flex-1">
          <div className="grid grid-cols-3 gap-3">
            {tutorsList &&
              tutorsList.map(
                ({
                  firstName,
                  lastName,
                  professionalTitle,
                  availabilityType,
                  id,
                  hourlyRate,
                }: any) => (
                  <div
                    key={id}
                    className="bg-white roundedd-md overflow-hidden cursor-pointer"
                    onClick={() => {
                      setId(id);
                      setVisible(true);
                    }}
                    // onClick={handleClick}
                  >
                    <img
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQVc22FgIapuN5SmPjiN6uI3Vfj1uNO1ShdSg&s"
                      alt="tutor avatar"
                      className="h-[218px] rounded-t-lg w-full"
                    />
                    <div className="p-[16px]">
                      <h3 className="W2STMedium mb-[5px] text-gray900 md:text-[18px] text-[14px]">
                        {firstName + " " + lastName}
                      </h3>
                      <p>{id}</p>
                      <div className="mt-2">
                        <div className="flex gap-3 items-center">
                          <FiBriefcase style={{ color: "text-gray700" }} />
                          <span className="text-gray500 text-sm">
                            {professionalTitle}
                          </span>
                        </div>
                      </div>

                      <div className="mt-2 bg-warning50 rounded-md flex gap-2 items-center p-2 text-sm">
                        <LuAward />
                        <span className="text-gray500">
                          15 sessions (5 reviews)
                        </span>
                      </div>
                      <AvailabilityBadge availabilityType={availabilityType} />
                      <div className="flex justify-between mt-[16px]">
                        <div>
                          <p className="W2STMedium text-gray900">
                            ${hourlyRate}/hr
                          </p>
                          <h3 className="text-gray500">Hourly rate</h3>
                        </div>
                        <button className="text-white px-[27px] py-[10px] rounded-md bg-primary900 W2STMedium">
                          Book Tutor
                        </button>
                      </div>
                    </div>
                  </div>
                )
              )}
            <Drawer open={visible} onClose={() => setVisible(false)}>
              <TutorBookingForm id={id} />
            </Drawer>
          </div>
        </div>
      </div>
    </StudentDashboard>
  );
};

export default StudentTutors;
