import { message } from 'antd';
import axios from 'axios';

export const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});

axiosInstance.interceptors.request.use(
  async (config : any) => {
    try {
      const stringifyToken = localStorage.getItem('BETTY_ADMIN_USER_TOKEN');

      const tokenParsed = stringifyToken
        ? JSON.parse(stringifyToken).accessToken
        : null;

      if (tokenParsed && typeof tokenParsed === 'string' && config.headers) {
        config.headers.authorization = `Bearer ${tokenParsed}`;
      }
    } catch (error) {
      console.error('Error parsing token:', error);
      // toast.error('Failed to parse authentication token.');
    }
    return config;
  },
  async (error) => {
    message.error('Request error: ' + error.message);
    console.log('==> REFRESHHH <==', error);
    return Promise.reject(error);
  }
);