import { useMutation, UseMutationResult } from "react-query";
import { axiosInstance } from "../../../../utils/axiosInstance";
import { message } from "antd";
import { queryClient } from "../../../../services/queryClient";

interface SubjectPayload {
  subject: string;
}

export const useAddSubjectMutation = (): UseMutationResult<
  any,
  any,
  SubjectPayload
> => {
  const token = localStorage.getItem("W2ST_ADMIN_TOKEN");

  return useMutation(
    ({ subject }: SubjectPayload) =>
      axiosInstance
        .patch(
          "admins/tutors/add-tutor-subject",
          { subject },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => res.data),
    {
      onSuccess: async () => {
        message.success("Subject Created");
        queryClient.invalidateQueries("useGetSubjectsQuery");
      },
      onError: (error: any) => {
        message.error(
          error?.response?.data?.message || "Failed... Please try again."
        );
      },
    }
  );
};
