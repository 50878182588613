import React from "react";
// import { AiOutlineEdit } from "react-icons/ai";
// import { FaRegTrashCan } from "react-icons/fa6";
import Moment from "react-moment";
// import Moment from 'react-moment';

type Props = {
  degree: string;
  college: string;
  enrolled: string;
  graduated: string;
};

const EducationCard = ({ college, degree, enrolled, graduated }: Props) => {
  return (
    <div className="mb-[30px]">
      <p className="text-primary900 mb-1 font-bold">{college}</p>
      <p className="text-primary900 mb-1">{degree}</p>
      <p className="font-bold text-gray500">
        <Moment format="YYYY" interval={0}>
          {enrolled}
        </Moment>{" "}
        -{" "}
        <Moment format="YYYY" interval={0}>
          {graduated}
        </Moment>
      </p>
      {/* <div className="flex gap-5 mt-3">
        <span className="flex gap-2 items-center text-primary600 cursor-pointer">
          <AiOutlineEdit className="text-2xl" />
          <span>Edit</span>
        </span>
        <span className="flex gap-2 items-center text-error500 cursor-pointer">
          <FaRegTrashCan />
          <span>Delete</span>
        </span>
      </div> */}
    </div>
  );
};

export default EducationCard;
