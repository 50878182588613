import React, { useState } from "react";
// import { FaRegTrashAlt } from "react-icons/fa";
// import { GoPlus } from "react-icons/go";
import { Link } from "react-router-dom";
// import { useOnboardTutorMutation } from "../../hooks/api/onboard/useOnboardTutorMutation";
import { useGetTutorQuery } from "../../../hooks/api/Tutors/Auth/useGetTutorQuery";
// import { message } from "antd";
import LevelOne from "./LevelOne";
import LevelTwo from "./LevelTwo";
import LevelThree from "./LevelThree";
import LevelFour from "./LevelFour";
import withTutorAuth from "../../../hoc/withTutorAuth";

const Onboarding = () => {
  const [level, setLevel] = useState(0);

  const Logo = require("../../../assets/images/logo.png");
  const SideImage1 = require("../../../assets/illustrations/image1.png");
  const SideImage2 = require("../../../assets/illustrations/image2.png");
  const SideImage3 = require("../../../assets/illustrations/image3.png");

  const Level1 = require("../../../assets/illustrations/level1.png");
  const Level2 = require("../../../assets/illustrations/level2.png");
  const Level3 = require("../../../assets/illustrations/level3.png");

  const { data: tutor } = useGetTutorQuery();

  return (
    <div className="md:flex block">
      <div className="md:w-[55%] h-screen flex flex-col">
        <div className="h-[10vh] w-full">
          <div className="p-6">
            <Link to="/">
              <img
                src={Logo}
                height={20}
                width={100}
                alt="Logo of Way2SuccessTutors"
              />
            </Link>
          </div>
        </div>
        <div className="relative flex-1 overflow-y-scroll">
          <div className="md:w-8/12 w-10/12 mx-auto">
            {tutor?.data?.onBoardingProgress === 0 && (
              <img src={Level1} alt="progress indicator" />
            )}
            {tutor?.data?.onBoardingProgress === 1 && (
              <img src={Level2} alt="progress indicator" />
            )}
            {tutor?.data?.onBoardingProgress === 2 && (
              <img src={Level3} alt="progress indicator" />
            )}

            <div className="flex flex-col h-[85vh]">
              <div className="mt-[50px] grow overflow-y-scroll">
                {tutor?.data?.onBoardingProgress === 0 && (
                  <div className="w-11/12">
                    <h3 className="text-2xl W2STMedium text-gray900">
                      Hello {tutor?.data?.firstName}
                    </h3>
                    <p className="text-gray500">
                      Alright, to setup your profile we need to know a bit about
                      you.
                    </p>
                    <LevelOne />
                  </div>
                )}
                {tutor?.data?.onBoardingProgress === 1 && (
                  <div className="w-11/12">
                    <h3 className="text-txl W2STMedium text-gray900">
                      Top-notch qualifications make for a compelling tutor.
                    </h3>
                    <p className="text-gray500">
                      Alright, to setup your profile we need to know a bit about
                      you.
                    </p>
                    <LevelTwo />
                  </div>
                )}
                {tutor?.data?.onBoardingProgress === 2 && (
                  <div className="w-11/12">
                    <h3 className="text-txl W2STBold text-gray900 mt-3">
                      Top-notch qualifications make for a compelling tutor.
                    </h3>
                    <p className="text-gray500">
                      Alright, to setup your profile we need to know a bit about
                      you.
                    </p>
                    <LevelThree />
                  </div>
                )}
                {tutor?.data?.onBoardingProgress === 3 && (
                  <div className="w-11/12">
                    <h3 className="text-txl W2STBold text-gray900 mt-3">
                      Set yor Availability
                    </h3>
                    <p className="text-gray500">Here's the final step</p>
                    <LevelFour />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-[45%] h-screen text-white bg-gradient-to-tr from-[#0F0068] to-[#9747FF] px-20 md:block hidden">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          {level === 0 && (
            <img src={SideImage1} alt="Logo of Way2SuccessTutors" />
          )}
          {level === 1 && (
            <img src={SideImage2} alt="Logo of Way2SuccessTutors" />
          )}
          {level === 2 && (
            <img src={SideImage3} alt="Logo of Way2SuccessTutors" />
          )}
        </div>
      </div>
    </div>
  );
};

export default withTutorAuth(Onboarding);
