/* eslint-disable react/no-unescaped-entities */
import React, { useRef, useState } from "react";

import { BsMap, BsQuote } from "react-icons/bs";
import { FiCalendar, FiDollarSign } from "react-icons/fi";
import { LuKey } from "react-icons/lu";
import Layout from "../Components/Layout";
import { Link } from "react-router-dom";
import Button from "../Components/Button";
import TutorTestimonials from "../Components/TutorTestimonials";
import StudentFAQ from "../Components/StudentFAQ";

// import Layout from "../../components/Layout";
// import Button from "../../components/Button";
// import Testimonials from "../../components/StudentTestimonials";
// import FAQ from "../../components/TutorFAQ";
// import TutorTestimonials from "../../components/TutorTestimonials";

const data = [
  {
    id: 1,
    icon: <BsMap />,
    title: "Teach Anywhere and Anytime",
    desc: "Teach online classes all over the US from the comfort of your home.",
  },
  {
    id: 2,
    icon: <FiCalendar />,
    title: "Take Control of Your Schedules",
    desc: "Teach online classes all over the US from the comfort of your home.",
  },
  {
    id: 3,
    icon: <LuKey />,
    title: "Access to Reliable Tutoring Jobs",
    desc: "Get paid X$/hour through our tutoring jobs. We handle advertising and billing while you expand your tutoring profile.",
  },
  {
    id: 4,
    icon: <FiDollarSign />,
    title: "Earn Extra Income",
    desc: "Instead of wasting time on classified ads multiple advertisements, you get direct access to well-paid tutoring jobs.",
  },
];

const steps = [
  {
    id: 1,
    title: "Register to Our Tutoring Service",
    desc: "Fill our online registration form to kick-start the application process. We need some basic information about your interests, education and professional experience.",
  },
  {
    id: 2,
    title: "Build Your Tutoring Profile",
    desc: "We take great pride in providing a diverse range of tutoring jobs for every grade-level and subject. Create your profile by selecting the subjects you’d like to teach and a tentative tutoring schedule.",
  },
  {
    id: 3,
    title: "Start Teaching",
    desc: "We provide interactive teaching tools and a well-designed online classroom for online classes. Take advantage of these features to elevate your lessons.",
  },
  {
    id: 4,
    desc: "On the whole, our quick and easy process makes tutoring immensely gratifying. Once you get through these steps, you can generate a steady supply of tutoring income.",
    title: "Finally",
  },
];

const Tutors = () => {
  const HeroBanner = require("../../../assets/images/herosection.webp");
  const TutorCard = require("../../../assets/images/tutorcard1.webp");
  const TestAvatar = require("../../../assets/images/avatar.webp");
  const SampleAvatar = require("../../../assets/images/sample.webp");
  const Meeting = require("../../../assets/images/meeting.webp");

  return (
    <Layout>
      <section className="container mx-auto py-20">
        <div>
          <div className="block md:flex items-center gap-[24px]">
            <div className="flex-1">
              <h3 className="text-dmd md:text-dxl text-gray700 W2STMedium">
                Become Part of a Growing Community of Tutors
              </h3>
              <p className="text-gray500 mt-[24px] text-tmd md:text-txl mb-[32px]">
                Whether you are a full-time tutor or just interested in earning
                extra cash, we have a tutoring job that fits your business
                goals. Join our tutoring service to create new teaching
                opportunities that align with your expertise and schedule.
              </p>
              <Link to="/apply">
                <Button title="Apply here" />
              </Link>
            </div>
            <div className="flex-1  text-black md:mt-0 mt-10">
              <img src={HeroBanner} alt="W2ST Tutor Banner" />
            </div>
          </div>
        </div>
      </section>
      <section className="container mx-auto md:py-20 pt-0 ">
        <div>
          <div className="block md:flex items-center gap-[64px]">
            <div className="flex-1 md:block hidden">
              <img src={TutorCard} alt="W2ST Tutor Banner" />
            </div>
            {/* <div className="flex-1  text-black">
              <h3 className="text-4xl text-gray-700 W2STMedium w-10/12">
                The only Tutoring profile you'll ever need.
              </h3>
              <p className="text-gray-500 my-3 text-xl">
                Craft a standout profile that highlights your expertise and
                teaching style, all in one place. Say goodbye to the hassle of
                managing multiple profiles.
              </p>
              <div className="flex gap-2">
                <Image
                  src={Avatar}
                  alt="testimonial"
                  style={{ height: '70px', width: '70px' }}
                />
                <div>
                  <div className="text-gray-900 italic W2STMedium">
                    "Experience the ease of connecting with highly qualified
                    educators for students"
                  </div>
                  <span className="italic text-gray-500">
                    Alfred Shittu -Student
                  </span>
                </div>
              </div>
            </div> */}
            <div className="flex-1  text-black mt-10 md:mt-0">
              {/* <h3 className="text-gray500 my-[24px] text-txl w-10/12"></h3> */}
              <h3 className="md:text-dmd text-txs text-gray700 W2STMedium w-10/12">
                The only Tutoring <br />
                profile you'll ever need.
              </h3>

              <p className="text-gray500 my-[24px] text-txl md:w-10/12 w-full">
                Craft a standout profile that highlights your expertise and
                teaching style, all in one place. Say goodbye to the hassle of
                managing multiple profiles.
              </p>
              <div className="flex gap-2">
                <img
                  src={TestAvatar}
                  alt="testimonial"
                  className="h-[70px] w-[70px]"
                />
                <div>
                  <div className="text-gray900 italic W2STMedium w-full md:w-8/12">
                    "W2ST tutor's approach helped me tackle tough concepts,
                    boosting my confidence in the subject."
                  </div>
                  <span className="italic text-gray500">
                    Alfred Shittu - Student
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-gray-50 py-[90px]">
        <div className="container mx-auto">
          <div className="block md:flex gap-[90px]">
            <div className="w-full md:w-6/12 md:mb-0 mb-10">
              {/* <Image src={SampleAvatar} alt="testimonial" className="w-full" /> */}
              <img src={SampleAvatar} className="w-full" alt="" />
            </div>
            <div className="block md:w-6/12">
              <p className="text-gray500 mb-[17px] text-tsm">
                WHY TUTORS LOVE US
              </p>
              <h3 className="text-gray700 text-txs md:text-dmd W2STMedium pt-[17px] md:pb-[49px] pb-[25px]">
                We eliminate common tutoring headaches to simplify the tutoring
                process.
              </h3>
              <div className="grid grid-cols-2 gap-4">
                {data.map(({ title, desc, id, icon }) => (
                  <div key={id} className="md:mb-[32px] mb-[16px]">
                    <div className="rounded-full p-3 border-purple-200 border-[1px] inline-block text-purple-700 font-bold">
                      {icon}
                    </div>
                    <h3 className="W2STBold text-gray700 my-[16px]">{title}</h3>
                    <p className="text-gray500 text-tsm w-8/12">{desc}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container mx-auto py-20">
        <div>
          <div className="block md:flex gap-[300px]">
            <div className="md:w-6/12 w-full">
              <p className="text-gray500 mb-[12px] md:mb-[24px] text-tsm">
                HOW IT WORKS
              </p>
              <h3 className="text-gray700 W2STMedium text-dsm md:text-dlg mb-[12px] md:mb-[24px]">
                How to Become a Tutor?
              </h3>
              <p className="text-gray500 mb-[12px] md:mb-[24px]">
                Unlock the power of our online platform, where we source the
                finest tutors across all age groups and academic levels.
              </p>
              <Link to="/tutors">
                <Button title="Book a tutor" />
              </Link>
              {/* <Image src={Meeting} alt="" /> */}
              <img src={Meeting} alt="" className="mt-[50px]" />
            </div>
            <div className="md:w-6/12 w-full mt-[20px] md:mt-0">
              <div className="rounded-md bg-gray-100 border-[1px] p-4">
                {steps.map(({ id, title, desc }) => (
                  <div className="mb-5" key={id}>
                    {/* <p className="uppercase text-primary700">{id}</p> */}
                    <h3 className="text-gray700 text-tlg W2STMedium mb-[12px]">
                      {title}
                    </h3>
                    <div
                      className={`text-gray500 my-3 text-tsm  ${
                        id < 4
                          ? "border-b-[1px] border-gray300 mb-[24px] pb-[24px]"
                          : ""
                      }`}
                    >
                      {desc}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <TutorTestimonials />
      <StudentFAQ />
    </Layout>
  );
};

export default Tutors;
