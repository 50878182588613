export const qualifications = [
  {
    "degree": "Associate of Arts (AA)",
    "professional_title": "Associate"
  },
  {
    "degree": "Associate of Science (AS)",
    "professional_title": "Associate"
  },
  {
    "degree": "Associate of Applied Science (AAS)",
    "professional_title": "Technician, Technologist"
  },
  {
    "degree": "Bachelor of Arts (BA)",
    "professional_title": "B.A. Graduate"
  },
  {
    "degree": "Bachelor of Science (BS)",
    "professional_title": "B.S. Graduate"
  },
  {
    "degree": "Bachelor of Fine Arts (BFA)",
    "professional_title": "Bachelor of Fine Arts"
  },
  {
    "degree": "Bachelor of Business Administration (BBA)",
    "professional_title": "BBA Graduate, Business Administrator"
  },
  {
    "degree": "Bachelor of Engineering (BEng)",
    "professional_title": "Engineer"
  },
  {
    "degree": "Bachelor of Computer Science (BCS)",
    "professional_title": "Computer Scientist, Software Developer"
  },
  {
    "degree": "Master of Arts (MA)",
    "professional_title": "Master of Arts"
  },
  {
    "degree": "Master of Science (MS)",
    "professional_title": "Master of Science"
  },
  {
    "degree": "Master of Business Administration (MBA)",
    "professional_title": "MBA Graduate, Business Executive, Business Manager"
  },
  {
    "degree": "Master of Fine Arts (MFA)",
    "professional_title": "Master of Fine Arts"
  },
  {
    "degree": "Master of Engineering (MEng)",
    "professional_title": "Engineer"
  },
  {
    "degree": "Master of Education (MEd)",
    "professional_title": "Educator, Teacher, School Administrator"
  },
  {
    "degree": "Master of Public Health (MPH)",
    "professional_title": "Public Health Professional, Epidemiologist"
  },
  {
    "degree": "Master of Social Work (MSW)",
    "professional_title": "Social Worker"
  },
  {
    "degree": "Master of Laws (LLM)",
    "professional_title": "Attorney, Lawyer"
  },
  {
    "degree": "Master of Library Science (MLS)",
    "professional_title": "Librarian, Archivist"
  },
  {
    "degree": "Doctor of Philosophy (PhD)",
    "professional_title": "Doctor, Professor, Researcher"
  },
  {
    "degree": "Doctor of Medicine (MD)",
    "professional_title": "Physician, Doctor"
  },
  {
    "degree": "Doctor of Dental Surgery (DDS)",
    "professional_title": "Dentist"
  },
  {
    "degree": "Doctor of Pharmacy (PharmD)",
    "professional_title": "Pharmacist"
  },
  {
    "degree": "Doctor of Jurisprudence (JD)",
    "professional_title": "Attorney, Lawyer, Judge"
  },
  {
    "degree": "Doctor of Education (EdD)",
    "professional_title": "Educational Administrator, School Superintendent, Professor"
  },
  {
    "degree": "Doctor of Nursing Practice (DNP)",
    "professional_title": "Nurse Practitioner (NP), Nursing Educator"
  },
  {
    "degree": "Doctor of Physical Therapy (DPT)",
    "professional_title": "Physical Therapist"
  },
  {
    "degree": "Master of Architecture (MArch)",
    "professional_title": "Architect"
  },
  {
    "degree": "Doctor of Veterinary Medicine (DVM)",
    "professional_title": "Veterinarian"
  },
  {
    "degree": "Master of Public Administration (MPA)",
    "professional_title": "Public Administrator, Government Official"
  }
]
