import { useQuery } from "react-query";
import { axiosInstance } from "../../../../utils/axiosInstance";

export const useGetTutorQuery = (id: any) => {
  const token = localStorage.getItem("W2ST_STUDENT_TOKEN");

  return useQuery({
    queryKey: ["getTutor", id],
    queryFn: async () => {
      if (!token) throw new Error("Authorization token is missing");

      const response = await axiosInstance.get(`users/tutors/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    },
    onSuccess: (data) => {
      console.log("Success Getting Tutors ==>", data);
    },
    onError: (error: unknown) => {
      console.error("Failed Getting Tutors ==>", error);
    },
    enabled: !!token,
  });
};
