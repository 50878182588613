import React, { useState } from "react";
import { useGetTutorQuery } from "../../../../hooks/api/Students/Tutors/useGetTutorQuery";
import { Spin } from "antd";
import { useCreatePaymentIntentMutation } from "../../../../hooks/api/Students/Bookings/useCreatePaymentIntentMutation";
import { useElements, useStripe } from "@stripe/react-stripe-js";

type Payload = {
  tutorId: string;
  subject: string;
  tutorShipType: "Virtual Tutoring" | "Physical Tutoring";
  date: string;
  startTime: string;
  endTime: string;
  notes: string;
};

const TutorBookingForm = ({ id }: { id: string }) => {
  const [formData, setFormData] = useState<Payload>({
    tutorId: id,
    subject: "",
    tutorShipType: "Virtual Tutoring",
    date: "",
    startTime: "",
    endTime: "",
    notes: "",
  });

  const { data: tutor, isLoading } = useGetTutorQuery(id);

  const stripe = useStripe();
  const elements = useElements();

  const handleChange = (
    e: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSelectTimeSlot = (
    day: string,
    startTime: string,
    endTime: string
  ) => {
    const today = new Date();
    const dayIndex = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ].indexOf(day);
    const currentDayIndex = today.getDay();
    const offset =
      dayIndex >= currentDayIndex
        ? dayIndex - currentDayIndex
        : 7 - (currentDayIndex - dayIndex);
    const selectedDate = new Date(today);
    selectedDate.setDate(today.getDate() + offset);

    setFormData({
      ...formData,
      date: selectedDate.toISOString(),
      startTime,
      endTime,
    });
  };

  const handleCancelTimeSlot = () => {
    setFormData((prev) => ({
      ...prev,
      date: "",
      startTime: "",
      endTime: "",
    }));
  };

  const { mutateAsync } = useCreatePaymentIntentMutation(formData);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!formData.subject || !formData.date || !formData.startTime) {
      alert("Please complete all required fields.");
      return;
    }
    mutateAsync();
  };

  return (
    <div>
      {isLoading && !tutor && <Spin />}
      <form
        onSubmit={handleSubmit}
        className="space-y-4 p-4 border rounded shadow"
      >
        <div>
          <label className="block text-sm font-medium">Subject</label>
          <select
            name="subject"
            value={formData.subject}
            onChange={handleChange}
            className="border-[1px] rounded-md border-gray300 p-4 block mt-3 outline-none w-full"
            required
          >
            <option value="" disabled>
              Select a subject
            </option>
            {tutor?.data?.areaOfExpertise?.length > 0 ? (
              tutor.data.areaOfExpertise.map((subject: string) => (
                <option key={subject} value={subject}>
                  {subject}
                </option>
              ))
            ) : (
              <option disabled>No subjects available</option>
            )}
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium">Tutorship Type</label>
          <select
            name="tutorShipType"
            value={formData.tutorShipType}
            onChange={handleChange}
            className="border-[1px] rounded-md border-gray300 p-4 block mt-3 outline-none w-full"
          >
            <option value="Virtual Tutoring">Virtual Tutoring</option>
            <option value="Physical Tutoring">Physical Tutoring</option>
          </select>
        </div>

        {formData.startTime && formData.endTime ? (
          <div>
            <h3 className="text-lg font-semibold mb-4">
              Selected Time Slot: {formData.startTime} - {formData.endTime} on{" "}
              {new Date(formData.date).toLocaleDateString()}
            </h3>
            <button
              type="button"
              onClick={handleCancelTimeSlot}
              className="bg-red-500 text-white py-1 px-3 rounded hover:bg-red-600"
            >
              Cancel Selection
            </button>
          </div>
        ) : (
          <div>
            <h3 className="text-lg font-semibold mb-4">Available Time Slots</h3>
            <div className="mt-6 h-[200px] overflow-y-scroll">
              {tutor?.data?.weeklyAvailability?.length > 0 ? (
                tutor.data.weeklyAvailability.map(
                  ({ day, timeSlots }: { day: string; timeSlots: any }) => (
                    <div className="flex mb-3" key={day}>
                      <div className="w-4/12 font-medium">{day}</div>
                      <div className="w-8/12">
                        {timeSlots?.map(
                          ({ startTime, endTime }: any, index: number) => (
                            <div
                              key={`${startTime}-${index}`}
                              className="mb-3 flex justify-between items-center"
                            >
                              <span className="text-sm">
                                {startTime} - {endTime}
                              </span>
                              <button
                                onClick={() =>
                                  handleSelectTimeSlot(day, startTime, endTime)
                                }
                                className="bg-green-500 text-white text-sm px-3 py-1 rounded hover:bg-green-600"
                              >
                                Select
                              </button>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  )
                )
              ) : (
                <p>No available time slots.</p>
              )}
            </div>
          </div>
        )}

        <div>
          <label className="block text-sm font-medium">Notes (optional)</label>
          <textarea
            name="notes"
            value={formData.notes}
            onChange={handleChange}
            placeholder="Add any additional notes here..."
            className="resize-none border-[1px] rounded-md border-gray300 p-4 block mt-3 outline-none w-full"
          />
        </div>

        <button
          type="submit"
          className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
        >
          Submit
        </button>
        <div className="p-3 bg-black">
          <button
            className="p-3 bg-yellow-400 text-white"
            type="submit"
            disabled={!stripe || !elements}
          >
            Pay
          </button>
        </div>
      </form>
      {/* <p>{JSON.stringify(formData)}</p> */}
    </div>
  );
};

export default TutorBookingForm;
