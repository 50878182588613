import React from "react";
import { PiTelevisionSimpleBold } from "react-icons/pi";
import { HiOutlineLocationMarker } from "react-icons/hi";

interface AvailabilityBadgeProps {
  availabilityType: "Virtual Tutoring" | "Physical Tutoring" | "Both";
}

const AvailabilityBadge: React.FC<AvailabilityBadgeProps> = ({
  availabilityType,
}) => {
  return (
    <div className="mt-3 text-[12px] flex gap-1">
      {availabilityType === "Virtual Tutoring" && (
        <span className="rounded-full px-3 py-2 text-pink500 bg-pink25 flex items-center gap-2">
          <PiTelevisionSimpleBold />
          <span>Virtual Tutoring</span>
        </span>
      )}

      {availabilityType === "Physical Tutoring" && (
        <span className="rounded-full px-3 py-2 text-indigo700 bg-indigo50 flex items-center gap-2">
          <HiOutlineLocationMarker />
          <span>Physical Tutoring</span>
        </span>
      )}

      {availabilityType === "Both" && (
        <>
          <span className="rounded-full px-3 py-2 text-indigo700 bg-indigo50 flex items-center gap-2">
            <HiOutlineLocationMarker />
            <span>Physical Tutoring</span>
          </span>
          <span className="rounded-full px-3 py-2 text-pink500 bg-pink25 flex items-center gap-2">
            <PiTelevisionSimpleBold />
            <span>Virtual Tutoring</span>
          </span>
        </>
      )}
    </div>
  );
};

export default AvailabilityBadge;
