import React from "react";
import TutorDashboardLayout from "../../../layout/TutorDashboardLayout";
import { useGetTutorQuery } from "../../../hooks/api/Tutors/Auth/useGetTutorQuery";
// import Layout from '../../components/Layout';
// import Card from '../../components/primary/Card';
// import PayoutCard from './PayoutCard';
// import AvailabilityCard from './AvailabilityCard';
// import BlogCard from './BlogCard';
// import ProfileCard from '../../components/primary/ProfileCard';
// import SessionCard from './SessionCard';
// import ProfileCompletness from './ProfileCompletness';
// import { useAppContext } from '../../hooks/useAppContext';
// import { useAppContext } from '../../hooks/useAppContext';
// import { useGetUserQuery } from '../../hooks/api/useGetUserQuery';
// import Cookies from 'js-cookie';
// import axios from 'axios';

const TutorProfile = () => {
  const { data: tutor } = useGetTutorQuery();
  // const image = require('../../../assets/images/avatar.png');

  // const data = JSON.parse(localStorage.getItem('@usertoken'));
  // const userData = JSON.parse(localStorage.getItem('@user'));
  // console.log('---data--- usertoken ==>', data);
  // console.log('---data--- userData ==>', userData?._id);
  // const userData = localStorage.getItem('user');
  // const parsedData = JSON.parse(userData);

  // console.log('userdata', parsedData);

  // const check = Cookies.get('wst');
  // console.log('---OVERVIEW---', check);

  // console.log('userDatauserDatauserData', data._id);
  // console.log('ID:', parsedData?._id);

  // const { data: user } = useGetUserQuery(parsedData._id);
  // console.log('USER:', user);

  // useEffect(() => {
  //   console.log('token: ', Cookies.get('w2st-c'));
  //   let config = {
  //     method: 'get',
  //     maxBodyLength: Infinity,
  //     url: 'https://w2st-server.onrender.com/api/v1/tutors/655446af9d1918908f089b07',
  //     headers: {
  //       Cookie: `w2st-c=${Cookies.get('w2st-c')}`,
  //     },
  //   };

  //   axios
  //     .request(config)
  //     .then((response) => {
  //       console.log('response: ', JSON.stringify(response.data));
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, []);

  //   const { tutor } = useAppContext();

  return (
    <TutorDashboardLayout>
      <p>{JSON.stringify(tutor?.data)}</p>
      {/* <div className="md:flex block md:gap-2">
        <section className="md:w-8/12 w-full">
          <section className="p-4 bg-white rounded-lg my-4">
            <div className="pb-5 border-b-[1px] border-gray200">
              <h3 className="text-2xl W2STBold">
                Welcome
                {tutor && tutor?.completed !== false
                  ? ''
                  : `, ${tutor?.firstname}`}
              </h3>
              <p>
                You have{' '}
                <span className="W2STBold underline text-primary700">
                  2 upcoming sessions
                </span>
              </p>
            </div>
            <ProfileCompletness />

            <h3 className="text-gray900 mb-3 mt-6 text-lg">Profile overview</h3>
            <div className="flex gap-2">
              <div className="p-4 bg-gray25 rounded-lg w-full border-[1px] border-gray100">
                <h3 className="text-gray500">Total session time</h3>
                <p className="W2STBold md:text-3xl text-lg text-gray900">
                  1,210 Mins
                </p>
              </div>
              <div className="p-4 bg-gray25 rounded-lg w-full border-[1px] border-gray100">
                <h3 className="text-gray500">Average rating</h3>
                <p className="W2STBold md:text-3xl text-lg text-gray900">
                  4.5/5
                </p>
              </div>
            </div>
          </section>
          <Card width="12">
            <section className="flex justify-between items-center ">
              <h3 className="text-gray900 text-lg">Recent Sessions</h3>
              <span className="text-primary600">View all</span>
            </section>
            <section className="mt-5">
              <SessionCard
                image={image}
                name="Noah Pierre"
                subject="Elementary English"
                sessionType="Physical"
                duration="120"
              />
              <SessionCard
                image={image}
                name="Noah Pierre"
                subject="Elementary English"
                sessionType="Physical"
                duration="120"
              />
              <SessionCard
                image={image}
                name="Noah Pierre"
                subject="Elementary English"
                sessionType="Physical"
                duration="120"
              />
            </section>
          </Card>
        </section>
        <section className="md:w-4/12 w-full">
          <section className="md:p-4 w-full md:mt-0 mt-4">
            <ProfileCard />
            <div className="p-4 bg-white rounded-lg">
              <h3 className="W2STBold font-2xl text-gray900">Payout</h3>
              <PayoutCard amount="1,210" date="July 23, 2023" status="paid" />
              <PayoutCard amount="210" date="July 31, 2023" status="pending" />
            </div>
            <AvailabilityCard />
            <BlogCard />
          </section>
        </section>
      </div> */}
    </TutorDashboardLayout>
  );
};

export default TutorProfile;
