import { useMutation } from "react-query";
import { axiosInstance } from "../../../../utils/axiosInstance";
import { message } from "antd";
import { queryClient } from "../../../../services/queryClient";

interface Education {
  college: string;
  majors: string;
  degree: string;
  enrolled: string;
  graduated: string | null;
}

interface WorkExperience {
  title: string;
  company: string;
  startDate: string;
  endDate: string | null;
  description: string;
}

interface TutorDetails {
  education: Education[];
  workExperience: WorkExperience[];
  stillAStudent: boolean;
}

export const useLevel3Onboarding = () => {
  const token = localStorage.getItem("W2ST_TUTOR_TOKEN");

  return useMutation(
    (payload: TutorDetails) =>
      axiosInstance
        .post("tutors/onboarding-step-three", payload, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res: any) => res.data),
    {
      onSuccess: async (data: any) => {
        message.success(data?.message);
        queryClient.invalidateQueries("gettutor");
      },
      onError: (error: any) => {
        message.error(
          error?.response?.data?.message
        );
      },
    }
  );
};
