import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../../../../utils/axiosInstance";
import { message } from "antd";

interface Props {
  id: string;
  otp: string;
}

export const useVerifyAdmin = () => {
  const navigate = useNavigate();

  return useMutation(
    (payload: Props) =>
      axiosInstance
        .post("/admins/verify", payload)
        .then((res: any) => res.data),
    {
      onSuccess: async (data: any) => {
        localStorage.setItem("W2ST_ADMIN_TOKEN", data?.data?.token);
        navigate("/admin/overview");
      },
      onError: (error: any) => {
        message.error(
          error?.response?.data?.message
        );
      },
    }
  );
};
