import React, { ReactNode, useState } from "react";
import { GrNotification } from "react-icons/gr";
import { FiBook, FiCalendar, FiGrid, FiSettings } from "react-icons/fi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import withStudentAuth from "../hoc/withStudentAuth";
import { useGetStudentQuery } from "../hooks/api/Students/Auth/useGetStudentQuery";
import { Modal } from "antd";
import { useSendVerifyOTPMutation } from "../hooks/api/Students/Auth/useSendOTPMutation";
import { useVerifyOTPMutation } from "../hooks/api/Students/Auth/useVerifyOTPMutation";

interface MyComponentProps {
  children: ReactNode;
}

const StudentDashboard = ({ children }: MyComponentProps) => {
  const location = useLocation();

  const Logo = require("../assets/images/logo.png");

  const [level, setLevel] = useState(0);
  const [otp, setOTP] = useState("");
  const { data, isLoading } = useGetStudentQuery();
  const { mutateAsync } = useSendVerifyOTPMutation();
  const { mutateAsync: verifyUser } = useVerifyOTPMutation(otp);

  const [visibility, setVisibility] = useState(false);
  const navigate = useNavigate();

  const handleDragStart = (e: React.DragEvent<HTMLImageElement>) => {
    e.preventDefault();
  };

  const activeLink = "text-primary700 bg-primary50 text-medium";
  const inActiveLink = "text-gray700";

  const handleInitialize = () => {
    mutateAsync();
    setLevel(1);
  };

  const handleVerify = () => {
    verifyUser();
  };

  return (
    <>
      {/* <Modal
        width="400px"
        footer={null}
        closeIcon={false}
        open={data?.data?.isEmailVerified === false}
        onCancel={() => {
          setVisibility(false);
        }}
      >
        <h3 className="text-xl font-bold">Email Verification</h3>
        <p>
          Proceed to verify your email address. An OTP been sent to your email
          for verfication purposes.
        </p>
        {level === 0 ? (
          <div className="block">
            <button
              onClick={() => handleInitialize()}
              className={`block text-center mt-4 py-5 w-full mb-4 rounded-md text-white text-md ${
                !isLoading ? "bg-primary500" : "bg-primary200"
              }`}
            >
              Proceed
            </button>
          </div>
        ) : (
          <div>
            <label htmlFor="otp" className="font-gray mt-3 block">
              Email OTP
            </label>
            <input
              name="otp"
              type="number"
              placeholder=""
              value={otp}
              className="px-6 py-4 my-3 rounded-md w-full cursor-pointer bg-slate-100"
              onChange={(e) => setOTP(e.target.value)}
            />
            <button
              onClick={() => handleVerify()}
              className={`block text-center mt-4 py-5 w-full mb-4 rounded-md text-white text-md ${
                !isLoading ? "bg-primary500" : "bg-primary200"
              }`}
            >
              Submit
            </button>
          </div>
        )}
      </Modal> */}

      <div className="md:block hidden overflow-y-hidden max-h-dvh">
        <div className="flex flex-col h-[100vh]">
          {/* Header Section */}
          <div className="border-b-[1px] border-b-gray300 flex-none">
            <div className="container mx-auto flex justify-between items-center p-4 bg-white">
              <span className="flex gap-3 items-center">
                <Link to="/">
                  <img
                    src={Logo}
                    alt="W2ST Logo"
                    className="logo"
                    onDragStart={handleDragStart}
                  />
                </Link>

                <span className="flex gap-3 ml-6 text-sm">
                  <Link
                    to="/student/overview"
                    className={`p-2 rounded-md flex gap-2 items-center ${
                      location?.pathname.includes("student/overview")
                        ? `${activeLink}`
                        : `${inActiveLink}`
                    }`}
                  >
                    {location?.pathname.includes("/student/overview") && (
                      <FiGrid />
                    )}
                    Overview
                  </Link>
                  <Link
                    to="/student/tutors"
                    className={`p-2 rounded-md flex gap-2 items-center ${
                      location?.pathname.includes("student/tutors")
                        ? `${activeLink}`
                        : `${inActiveLink}`
                    }`}
                  >
                    {location?.pathname.includes("/student/tutors") && (
                      <FiBook />
                    )}
                    Tutors
                  </Link>
                  <Link
                    to="/student/bookings"
                    className={`p-2 rounded-md flex gap-2 items-center ${
                      location?.pathname.includes("student/bookings")
                        ? `${activeLink}`
                        : `${inActiveLink}`
                    }`}
                  >
                    {location.pathname.includes("/student/bookings") && (
                      <FiCalendar />
                    )}
                    Bookings
                  </Link>
                </span>
              </span>
              <section className="flex gap-4 items-center text-gray500">
                <Link to="/student/settings" className="cursor-pointer">
                  <FiSettings
                    className={
                      location?.pathname.includes("/student/settings")
                        ? "text-primary600"
                        : "text-gray500"
                    }
                    style={{ fontSize: "20px" }}
                  />
                </Link>
                <GrNotification
                  className=" text-gray500"
                  style={{ fontSize: "20px" }}
                />
                <img
                  src={""}
                  alt="avatar"
                  className="logo h-[40px] w-[40px] rounded-full bg-gray-300"
                />
                <button
                  className="text-red-700 p-3"
                  onClick={() => {
                    localStorage.removeItem("W2ST_STUDENT_TOKEN");
                    navigate("/student/signin");
                  }}
                >
                  Logout
                </button>
              </section>
            </div>
          </div>

          {/* Scrollable Content Section */}
          <div className="bg-gray50 grow overflow-y-scroll">
            <div className="container mx-auto pt-10">
              {/* <p className="text-[10px]">{JSON.stringify(data?.data)}</p> */}
              <div>{children}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="md:hidden flex flex-col h-[100vh]">
        <div className="flex-none border-b-[1px] border-b-gray300">
          <div className="container mx-auto flex justify-between items-center p-4 bg-white">
            <span className="flex gap-3 items-center">
              <img src={Logo} alt="W2ST Logo" className="logo" />
            </span>
            <span className="rounded-md h-[40px] w-[40px] bg-[#3d3d3d]"></span>
          </div>
        </div>
        <div className="grow container mx-auto px-4 overflow-y-scroll bg-gray50">
          <div>{children}</div>
        </div>
        <div className="flex-none p-4 grid grid-cols-3 gap-2 mb-[30px]">
          <Link
            to="/student/overview"
            className={`p-2 rounded-md flex gap-2 items-center text-center text-tmd ${
              location.pathname === "/student/overview"
                ? "text-primary700 bg-primary50"
                : "text-gray700"
            }`}
          >
            <FiGrid />
            Overview
          </Link>
          <Link
            to="/student/tutors"
            className={`p-2 rounded-md flex gap-2 items-center text-tmd  ${
              location.pathname === "/student/tutors"
                ? "text-primary700 bg-primary50"
                : "text-gray700"
            }`}
          >
            <FiBook />
            Tutors
          </Link>
          <Link
            to="/student/bookings"
            className={`p-2 rounded-md flex gap-2 items-center text-tmd  text-center ${
              location.pathname === "/student/bookings"
                ? "text-primary700 bg-primary50"
                : "text-gray700"
            }`}
          >
            <FiCalendar />
            Bookings
          </Link>
        </div>
      </div>
    </>
  );
};

export default withStudentAuth(StudentDashboard);
