import React from "react";
import AdminDashboardLayout from "../../../layout/AdminDashboardLayout";
import Back from "../../../components/Back";

const StudentDetails = () => {
  return (
    <AdminDashboardLayout>
      <Back />
      <div className="mt-3">StudentDetails</div>
    </AdminDashboardLayout>
  );
};

export default StudentDetails;
