import { useMutation } from "react-query";
import { axiosInstance } from "../../../../utils/axiosInstance";
import { message } from "antd";

type Props = {
  "tutorId": string,
  "subject": string,
  "tutorShipType": string,  
  "date": string,
  "startTime": string,
  "endTime": string,
  "notes": string
}


export const useCreatePaymentIntentMutation = (payload: Props) => {

  const token = localStorage.getItem("W2ST_STUDENT_TOKEN");

  return useMutation(
    () =>
      axiosInstance
        .post(
          "bookings/payment",
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res: any) => res.data),
    {
      onSuccess: async () => {
        message.success("Payment Initiated");
      },
      onError: (error: any) => {
        message.error(
          error?.response?.data?.message ||
            "Failed... Please try again."
        );
      },
    }
  );
};
