import React, { useState } from "react";
import { useLevel3Onboarding } from "../../../../hooks/api/Tutors/Onboarding/useLevel3Onboarding";
import { GoPlus } from "react-icons/go";

const LevelThree = () => {
  const [payload, setPayload] = useState({
    education: [
      {
        college: "",
        majors: "",
        degree: "",
        enrolled: "",
        graduated: null,
      },
    ],
    workExperience: [
      {
        title: "",
        company: "",
        startDate: "",
        endDate: "",
        description: "",
      },
    ],
    stillAStudent: false,
  });

  const { mutateAsync } = useLevel3Onboarding();

  const handleSubmit = () => {
    mutateAsync(payload);
  };

  const handleEducationChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { name, value } = e.target;
    const updatedEducation = [...payload.education];
    updatedEducation[index] = { ...updatedEducation[index], [name]: value };

    setPayload((prev) => ({
      ...prev,
      education: updatedEducation,
    }));
  };

  const addNewEducation = () => {
    setPayload((prev) => ({
      ...prev,
      education: [
        ...prev.education,
        {
          college: "",
          majors: "",
          degree: "",
          enrolled: "",
          graduated: null, // Initialize graduated as null
        },
      ],
    }));
  };
  const deleteEducation = (index: number) => {
    setPayload((prev) => ({
      ...prev,
      education: prev.education.filter((_, idx) => idx !== index),
    }));
  };
  const addNewWorkExperience = () => {
    setPayload((prev) => ({
      ...prev,
      workExperience: [
        ...prev.workExperience,
        {
          title: "",
          company: "",
          startDate: "",
          endDate: "",
          description: "",
        },
      ],
    }));
  };

  const deleteWorkExperience = (index: number) => {
    setPayload((prev) => ({
      ...prev,
      workExperience: prev.workExperience.filter((_, idx) => idx !== index),
    }));
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const { name, value } = e.target;

    setPayload((prev) => ({
      ...prev,
      workExperience: prev.workExperience.map((experience, idx) =>
        idx === index ? { ...experience, [name]: value } : experience
      ),
    }));
  };

  return (
    <div>
      <div>
        <h3 className="W2STMedium mb-4 text-2xl mt-2">Education</h3>
        {payload.education.map((edu, index) => (
          <div key={index} className="mb-6 border p-4 rounded-md relative">
            {index !== 0 && (
              <button
                onClick={() => deleteEducation(index)}
                className="absolute top-2 right-2 text-red-500 border border-red-500 rounded-md px-2 py-1 text-sm"
              >
                Delete
              </button>
            )}
            <label htmlFor={`college-${index}`} className="text-gray700">
              College
            </label>
            <input
              id={`college-${index}`}
              name="college"
              type="text"
              value={edu.college}
              onChange={(e) => handleEducationChange(e, index)}
              placeholder="Enter College Name"
              className="w-full border-[1px] rounded-md border-gray300 p-4 block mt-2 outline-none"
            />
            <label
              htmlFor={`majors-${index}`}
              className="text-gray700 mt-3 block"
            >
              Majors
            </label>
            <input
              id={`majors-${index}`}
              name="majors"
              type="text"
              value={edu.majors}
              onChange={(e) => handleEducationChange(e, index)}
              placeholder="Enter Majors"
              className="w-full border-[1px] rounded-md border-gray300 p-4 block mt-2 outline-none"
            />
            <label
              htmlFor={`degree-${index}`}
              className="text-gray700 mt-3 block"
            >
              Degree
            </label>
            <input
              id={`degree-${index}`}
              name="degree"
              type="text"
              value={edu.degree}
              onChange={(e) => handleEducationChange(e, index)}
              placeholder="Enter Degree"
              className="w-full border-[1px] rounded-md border-gray300 p-4 block mt-2 outline-none"
            />
            <label
              htmlFor={`dates-${index}`}
              className="text-gray700 mt-3 block"
            >
              Duration
            </label>
            <div className="flex gap-2 mt-2 justify-between">
              <input
                id={`enrolled-${index}`}
                name="enrolled"
                type="date"
                value={edu.enrolled}
                onChange={(e) => handleEducationChange(e, index)}
                className="border-[1px] rounded-md border-gray300 p-4 block outline-none w-6/12"
              />
              <input
                id={`graduated-${index}`}
                name="graduated"
                type="date"
                value={edu.graduated || ""} // Ensure that graduated is handled properly as either string or null
                onChange={(e) => handleEducationChange(e, index)}
                className="border-[1px] rounded-md border-gray300 p-4 block outline-none w-6/12"
              />
            </div>
          </div>
        ))}
        <button
          onClick={addNewEducation}
          className="text-gray700 flex gap-2 items-center rounded-md border-[1px] border-gray300 px-4 py-2"
        >
          <GoPlus />
          Add Education
        </button>
      </div>{" "}
      <div>
        <h3 className="W2STMedium mb-4 text-2xl mt-2">Work Experience</h3>
        {payload.workExperience.map((experience, index) => (
          <div key={index} className="mb-6 border p-4 rounded-md relative">
            {index !== 0 && (
              <button
                onClick={() => deleteWorkExperience(index)}
                className="absolute top-2 right-2 text-red-500 border border-red-500 rounded-md px-2 py-1 text-sm"
              >
                Delete
              </button>
            )}
            <label htmlFor={`title-${index}`} className="text-gray700">
              Title
            </label>
            <input
              id={`title-${index}`}
              name="title"
              type="text"
              value={experience.title}
              onChange={(e) => handleChange(e, index)}
              placeholder="Enter Job Title"
              className="w-full border-[1px] rounded-md border-gray300 p-4 block mt-2 outline-none"
            />
            <label
              htmlFor={`company-${index}`}
              className="text-gray700 mt-3 block"
            >
              Company
            </label>
            <input
              id={`company-${index}`}
              name="company"
              type="text"
              value={experience.company}
              onChange={(e) => handleChange(e, index)}
              placeholder="Enter Company Name"
              className="w-full border-[1px] rounded-md border-gray300 p-4 block mt-2 outline-none"
            />

            <label
              htmlFor={`dates-${index}`}
              className="text-gray700 mt-3 block"
            >
              Duration
            </label>
            <div className="flex gap-2 mt-2 justify-between">
              <input
                id={`startDate-${index}`}
                name="startDate"
                type="date"
                value={experience.startDate}
                onChange={(e) => handleChange(e, index)}
                className="border-[1px] rounded-md border-gray300 p-4 block outline-none w-6/12"
              />
              <input
                id={`endDate-${index}`}
                name="endDate"
                type="date"
                value={experience.endDate}
                onChange={(e) => handleChange(e, index)}
                className="border-[1px] rounded-md border-gray300 p-4 block outline-none w-6/12"
              />
            </div>

            <label
              htmlFor={`description-${index}`}
              className="text-gray700 mt-3 block"
            >
              Description
            </label>
            <textarea
              id={`description-${index}`}
              name="description"
              value={experience.description}
              onChange={(e) => handleChange(e, index)}
              placeholder="Describe your role"
              className="w-full border-[1px] rounded-md border-gray300 p-4 block mt-2 outline-none resize-none"
            />
          </div>
        ))}
        <button
          onClick={addNewWorkExperience}
          className="text-gray700 flex gap-2 items-center rounded-md border-[1px] border-gray300 px-4 py-2"
        >
          <GoPlus />
          Add work experience
        </button>
      </div>
      <button
        // disabled={
        //   !payload?.areaOfExpertise ||
        //   !payload?.professionalTitle ||
        //   !payload?.profilePicture
        // }
        className={`mt-8 w-full py-3 mb-4 bg-primary900 rounded-md text-white text-md float-right`}
        onClick={handleSubmit}
        // onClick={() => setLevel(level + 1)}
      >
        Submit
      </button>
    </div>
  );
};

export default LevelThree;
