import React, { useState } from "react";
import TutorDashboardLayout from "../../../layout/TutorDashboardLayout";
import { Switch } from "antd";
import { useCreateAvailability } from "../../../hooks/api/Tutors/Availability/useCreateAvailability";

interface TimeSlot {
  startTime: string;
  endTime: string;
}

interface DayAvailability {
  day: string;
  timeSlots: TimeSlot[];
}

interface Payload {
  weeklyAvailability: DayAvailability[];
}

const TutorAvailability: React.FC = () => {
  const [payload, setPayload] = useState<Payload>({
    weeklyAvailability: [
      { day: "Sunday", timeSlots: [] },
      { day: "Monday", timeSlots: [] },
      { day: "Tuesday", timeSlots: [] },
      { day: "Wednesday", timeSlots: [] },
      { day: "Thursday", timeSlots: [] },
      { day: "Friday", timeSlots: [] },
      { day: "Saturday", timeSlots: [] },
    ],
  });

  const { mutateAsync } = useCreateAvailability();

  const handleSubmit = () => {
    mutateAsync(payload);
  };

  const handleAddTimeSlot = (day: string) => {
    setPayload((prev) => ({
      ...prev,
      weeklyAvailability: prev.weeklyAvailability.map((availability) =>
        availability.day === day
          ? {
              ...availability,
              timeSlots: [
                ...availability.timeSlots,
                { startTime: "00:00", endTime: "01:00" }, // Default values
              ],
            }
          : availability
      ),
    }));
  };

  const handleUpdateTimeSlot = (
    day: string,
    index: number,
    field: "startTime" | "endTime",
    value: string
  ) => {
    setPayload((prev) => ({
      ...prev,
      weeklyAvailability: prev.weeklyAvailability.map((availability) =>
        availability.day === day
          ? {
              ...availability,
              timeSlots: availability.timeSlots.map((slot, i) =>
                i === index
                  ? {
                      ...slot,
                      [field]: value,
                      ...(field === "startTime" && {
                        endTime: calculateEndTime(value),
                      }),
                    }
                  : slot
              ),
            }
          : availability
      ),
    }));
  };

  const handleRemoveTimeSlot = (day: string, index: number) => {
    setPayload((prev) => ({
      ...prev,
      weeklyAvailability: prev.weeklyAvailability.map((availability) =>
        availability.day === day
          ? {
              ...availability,
              timeSlots: availability.timeSlots.filter((_, i) => i !== index),
            }
          : availability
      ),
    }));
  };

  const calculateEndTime = (startTime: string): string => {
    const [hours, minutes] = startTime.split(":").map(Number);
    const endHours = (hours + 1) % 24; // Ensure it wraps around after 23
    return `${String(endHours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}`;
  };

  return (
    <TutorDashboardLayout>
      <section className="p-4 bg-white rounded-lg my-4 mt-4">
        <h3 className="text-2xl W2STBold">Set Availability</h3>
        <div className="text-gray500 mt-2">
          We ensure that sessions are scheduled at your convenience. You have
          the freedom to personalize your availability, offering complete
          flexibility.
        </div>
        <div className="flex justify-end mt-6">
          <button
            onClick={handleSubmit}
            className="text-white bg-purple-600 px-4 py-3 rounded-md font-bold"
          >
            Submit
          </button>
        </div>

        <div className="section mt-4 p-3">
          {payload.weeklyAvailability.map((availability) => (
            <div
              className="flex items-center py-3 border-b-[1px] border-gray-300"
              key={availability.day}
            >
              <div className="w-4/12 flex items-center gap-2">
                <Switch checked={availability.timeSlots.length > 0} />
                <span>{availability.day.toUpperCase()}</span>
              </div>
              <div className="w-8/12">
                {availability.timeSlots.map((slot, index) => (
                  <Scheduler
                    key={index}
                    day={availability.day}
                    index={index}
                    startTime={slot.startTime}
                    endTime={slot.endTime}
                    onUpdateTimeSlot={handleUpdateTimeSlot}
                    onRemoveTimeSlot={handleRemoveTimeSlot}
                  />
                ))}
                <button
                  className="mt-2 text-blue-500 hover:underline"
                  onClick={() => handleAddTimeSlot(availability.day)}
                >
                  + Add Time Slot
                </button>
              </div>
            </div>
          ))}
        </div>
        {JSON.stringify(payload)}
      </section>
    </TutorDashboardLayout>
  );
};

interface SchedulerProps {
  day: string;
  index: number;
  startTime: string;
  endTime: string;
  onUpdateTimeSlot: (
    day: string,
    index: number,
    field: "startTime" | "endTime",
    value: string
  ) => void;
  onRemoveTimeSlot: (day: string, index: number) => void;
}

const Scheduler: React.FC<SchedulerProps> = ({
  day,
  index,
  startTime,
  endTime,
  onUpdateTimeSlot,
  onRemoveTimeSlot,
}) => {
  const times = Array.from(
    { length: 24 },
    (_, i) => `${String(i).padStart(2, "0")}:00`
  );

  return (
    <div className="flex items-center gap-3 mb-2">
      <div className="flex items-center gap-6">
        <span>Start Time</span>
        <select
          name="startTime"
          className="mt-1 w-[100px] rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm p-3"
          value={startTime}
          onChange={(e) =>
            onUpdateTimeSlot(day, index, "startTime", e.target.value)
          }
        >
          {times.map((time) => (
            <option key={time} value={time}>
              {time}
            </option>
          ))}
        </select>
      </div>
      <div className="flex items-center gap-6">
        <span>End Time</span>
        <select
          name="endTime"
          disabled
          className="mt-1 w-[100px] bg-gray-200 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm p-3"
          value={endTime}
          onChange={(e) =>
            onUpdateTimeSlot(day, index, "endTime", e.target.value)
          }
        >
          {times.map((time) => (
            <option key={time} value={time}>
              {time}
            </option>
          ))}
        </select>
      </div>
      <button
        className="text-red-500 hover:underline"
        onClick={() => onRemoveTimeSlot(day, index)}
      >
        Remove
      </button>
    </div>
  );
};

export default TutorAvailability;
