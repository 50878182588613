import React from "react";
import StudentDashboard from "../../../layout/StudentDashboard";
import Back from "../../../components/Back";
import { useParams } from "react-router-dom";

const StudentTutorDisplayPage = () => {
  const { id } = useParams();

  const data = [
    {
      day: "Monday",
      timeSlots: [
        { startTime: "09:00", endTime: "10:00" },
        { startTime: "10:00", endTime: "11:00" },
        { startTime: "11:00", endTime: "12:00" },
        { startTime: "12:00", endTime: "13:00" },
        { startTime: "13:00", endTime: "14:00" },
        { startTime: "14:00", endTime: "15:00" },
        { startTime: "15:00", endTime: "16:00" },
        { startTime: "16:00", endTime: "17:00" },
      ],
    },
    {
      day: "Tuesday",
      timeSlots: [
        { startTime: "09:00", endTime: "10:00" },
        { startTime: "10:00", endTime: "11:00" },
        { startTime: "11:00", endTime: "12:00" },
        { startTime: "12:00", endTime: "13:00" },
        { startTime: "13:00", endTime: "14:00" },
        { startTime: "14:00", endTime: "15:00" },
        { startTime: "15:00", endTime: "16:00" },
        { startTime: "16:00", endTime: "17:00" },
      ],
    },
    {
      day: "Wednesday",
      timeSlots: [
        { startTime: "09:00", endTime: "10:00" },
        { startTime: "10:00", endTime: "11:00" },
        { startTime: "15:00", endTime: "16:00" },
      ],
    },
  ];

  return (
    <StudentDashboard>
      <Back />
      {/* <div className="mt-3">{JSON.stringify(data)} </div> */}
      <div></div>
      {/* StudentTutorDisplayPage <p>{JSON.stringify(id)}</p> */}
      {/* <p></p> */}
    </StudentDashboard>
  );
};

export default StudentTutorDisplayPage;
