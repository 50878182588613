import React from 'react';

type Props = {};

const BlogCard = (props: Props) => {
  const Placeholder = require('../assets/images/delete1.png');

  return (
    <section className="rounded-md bg-primary50 p-3 mt-5">
      <h3 className="text-gray900 W2STBold">Discover the Latest Blog</h3>
      <p className="text-gray500 mb-2">
        Accelerate Your Hiring Process for Increased Earnings on
        way2successtutor platform
      </p>
      <img src={Placeholder} alt="" className="w-full" />
      <span className="text-primary500 underline cursor-pointer mt-4">
        Read now
      </span>
    </section>
  );
};

export default BlogCard;
