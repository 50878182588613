import React, { useState } from "react";
import { Button, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useLevel1Onboarding } from "../../../../hooks/api/Tutors/Onboarding/useLevel1Onboarding";
import { qualifications } from "../qualifications";
import { useGetSubjectsQuery } from "../../../../hooks/api/Admin/Subjects/useGetSubjectsQuery";
import { useUploadFile } from "../../../../hooks/misc/useUploadFile";

const LevelOne = () => {
  const [payload, setPayload] = useState({
    professionalTitle: "",
    profilePicture: "",
    areaOfExpertise: "",
  });

  const handleChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    setPayload({ ...payload, [name]: value });
  };

  const { mutateAsync } = useLevel1Onboarding();
  const page = 1;
  const { data } = useGetSubjectsQuery({ page });

  const {
    mutateAsync: uploadFile,
    isLoading: isUploading,
    data: image,
  } = useUploadFile();

  const handleFileChange = async (info: any) => {
    const { originFileObj } = info.file;

    if (originFileObj) {
      try {
        await uploadFile(originFileObj, {
          onSuccess: async (data) => {
            await setPayload({ ...payload, profilePicture: data?.data });
          },
        });
      } catch (error) {
        console.error("Upload failed:", error);
      }
    }
  };

  const handleSubmit = () => {
    mutateAsync(payload);
  };

  return (
    <div>
      <div className="w-full mt-4">
        {!image ? (
          <Upload onChange={handleFileChange} listType="picture">
            <Button type="dashed" icon={<UploadOutlined />}>
              Upload Profile Picture
            </Button>
          </Upload>
        ) : (
          <img
            src={image?.data}
            className="h-[70px] w-[70px] rounded-full mb-3"
            alt=""
          />
        )}

        <label htmlFor="professionalTitle" className="text-gray700">
          Professional title
        </label>
        <select
          name="professionalTitle"
          onChange={handleChange}
          value={payload?.professionalTitle}
          className="w-full border-[1px] rounded-md border-gray300 p-4 block mt-3 outline-none"
        >
          <option value="" disabled>
            Select Option
          </option>
          {qualifications &&
            qualifications.map(({ degree, professional_title }) => (
              <option value={degree} key={degree}>
                {professional_title}
              </option>
            ))}
        </select>
      </div>
      <div className="w-full my-4">
        <label htmlFor="areaOfExpertise" className="text-gray700">
          Area of expertise
        </label>
        {/* <input
          name="areaOfExpertise"
          type="text"
          value={payload.areaOfExpertise}
          onChange={handleChange}
          placeholder="Select an option"
          className="w-full border-[1px] rounded-md border-gray300 p-4 block mt-3 outline-none"
        /> */}
        <select
          name="areaOfExpertise"
          onChange={handleChange}
          className="w-full border-[1px] rounded-md border-gray300 p-4 block mt-3 outline-none"
        >
          <option value="" disabled>
            Select option
          </option>
          {data?.data?.docs &&
            data?.data?.docs?.map(({ subject }: { subject: string }) => (
              <option value={subject} className="capitalize">
                {subject}
              </option>
            ))}
        </select>
      </div>
      <button
        disabled={
          !payload?.areaOfExpertise ||
          !payload?.professionalTitle ||
          !payload?.profilePicture
        }
        className={`mt-8 w-full py-3 mb-4 bg-primary900 rounded-md text-white text-md float-right ${
          !payload?.areaOfExpertise ||
          !payload?.professionalTitle ||
          !payload?.profilePicture
            ? "opacity-50"
            : ""
        }`}
        onClick={handleSubmit}
        // onClick={() => setLevel(level + 1)}
      >
        Continue
      </button>
    </div>
  );
};

export default LevelOne;
