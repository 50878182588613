import React from "react";
import { BsQuote } from "react-icons/bs";

const TutorTestimonials = () => {
  const testimonials = [
    {
      id: 1,
      name: "Rebecca",
      school: "Havard University",
      content:
        "W2ST streamlines connections with students. Through the platform, I've provided top-notch guidance, witnessing remarkable academic progress.",
    },
    {
      id: 2,
      name: "David",
      school: "James",
      content:
        "W2ST facilitates seamless online tutoring experiences. I've helped students excel, thanks to the platform's user-friendly interface and efficient tools.",
    },
    {
      id: 3,
      name: "Lola",
      school: "Havard University",
      content:
        "W2ST enhances tutoring engagement. Utilizing its features, I've fostered meaningful learning interactions and witnessed students' notable improvements.",
    },
  ];

  const TestAvatar2 = require("../../../assets/images/avatar2.webp");

  return (
    <section className="py-20 bg-gradient-to-b from-white to-[#E2DDFF]">
      <p className="text-center uppercase text-gray500 mb-[24px]">
        TESTIMONIALS
      </p>
      <center className="text-txs md:text-dmd text-gray-700 mb-[52px] W2STMedium">
        What other tutors say
        <br /> about us
      </center>
      <div className="container mx-auto grid md:grid-cols-3 grid-cols-1 gap-4">
        {testimonials.map(({ id, name, school, content }) => (
          <div
            key={id}
            className="p-4 rounded-md bg-white border-primary300 border-[1px]"
          >
            <div className="text-dlg md:text-dxxl text-gray700">
              <BsQuote />
            </div>
            <div className="md:mt-4 W2STThin text-gray700 md:mb-10 text-tmd md:text-txl">
              {content}
            </div>
            <div className="flex mt-3 gap-3">
              {/* <Image
                src={TestAvatar2}
                alt="avatar"
                className="h-[50px] w-[50px]"
              /> */}
              <div>
                <div className="W2STMedium text-gray900 text-tmd md:text-txl">
                  {name}
                </div>
                <span className="text-gray700 text-tsm">Tutor</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default TutorTestimonials;
