import { useMutation } from "react-query";
import { axiosInstance } from "../../../../utils/axiosInstance";
import { message } from "antd";
import { useGetStudentQuery } from "./useGetStudentQuery";

export const useSendVerifyOTPMutation = () => {
  const { data: data } = useGetStudentQuery();

  const id = data?.data?.id;
  const token = localStorage.getItem("W2ST_STUDENT_TOKEN");

  return useMutation(
    () =>
      axiosInstance
        .post(
          `auth/send-otp/${id}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res: any) => res.data),
    {
      onSuccess: async () => {
        message.success("OTP sent successfully.");
      },
      onError: (error: any) => {
        message.error(
          error?.response?.data?.message ||
            "Failed to send OTP. Please try again."
        );
      },
    }
  );
};
