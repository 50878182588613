import React, { useState } from "react";
import { useLevel4Onboarding } from "../../../../hooks/api/Tutors/Onboarding/useLevel4Onboarding";

type Props = {};

const LevelFour = (props: Props) => {
  const [payload, setPayload] = useState({
    availabilityType: "Virtual Tutoring", // Default value
    isAvailable: true,
  });

  const handleAvailabilityChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setPayload((prev) => ({
      ...prev,
      availabilityType: e.target.value,
    }));
  };

  const { mutateAsync } = useLevel4Onboarding();

  const handleSubmit = async () => {
    try {
      await mutateAsync(payload);
    } catch (error) {
      console.error("Submission failed:", error);
    }
  };

  return (
    <div className="mt-10 bg-white border p-4 rounded-md">
      <div className="mt-4">
        <label
          htmlFor="availabilityType"
          className="block text-sm font-medium text-gray-700 mb-3"
        >
          Availability Type
        </label>
        <select
          id="availabilityType"
          value={payload.availabilityType}
          onChange={handleAvailabilityChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm p-3"
        >
          <option value="Virtual Tutoring">Virtual Tutoring</option>
          <option value="Physical Tutoring">Physical Tutoring</option>
          <option value="Both">Both</option>
        </select>
        <button
          disabled={!payload?.availabilityType || !payload?.isAvailable}
          className={`mt-8 w-full py-3 mb-4 bg-primary900 rounded-md text-white text-md ${
            !payload?.availabilityType || !payload?.isAvailable
              ? "opacity-50 cursor-not-allowed"
              : "hover:bg-primary700"
          }`}
          onClick={handleSubmit}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default LevelFour;
