import React from "react";
import { Link, useLocation } from "react-router-dom";

interface LayoutSideLinkProps {
  to: string;
  title: string;
  children: React.ReactNode;
}

const LayoutSideLink = ({ to, children, title }: LayoutSideLinkProps) => {
  const location = useLocation();

  return (
    <li className="my-8">
      <Link
        to={to}
        className={`flex gap-2 items-center W2STBold ${
          location.pathname === `${to}` ? "text-primary700" : "text-gray500"
        }`}
      >
        {children}
        <span
          className={`${
            location.pathname === `${to}` ? "text-primary700" : "text-gray700"
          }`}
        >
          {title}
        </span>
      </Link>
    </li>
  );
};

export default LayoutSideLink;
