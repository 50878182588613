import React from "react";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { PiTelevisionSimpleBold } from "react-icons/pi";

const AvailabilityCard = () => {
  return (
    <div className="mt-5 p-4 bg-white rounded-lg">
      <h3 className="font-bold text-gray900 mb-3">Available for</h3>
      <div className="mt-3 flex gap-3">
        <span className="rounded-full px-3 py-2 text-indigo700 bg-indigo50 text-sm flex items-center gap-2">
          <HiOutlineLocationMarker />
          <span>Physical Tutoring</span>
        </span>
        <span className="rounded-full px-3 py-2 text-pink500 bg-pink25 text-sm flex items-center gap-2">
          <PiTelevisionSimpleBold /> <span>Virtual Tutoring</span>
        </span>
      </div>
    </div>
  );
};

export default AvailabilityCard;
