import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useLoginAdmin } from "../../../hooks/api/Admin/Auth/useLoginAdmin";
import { useVerifyAdmin } from "../../../hooks/api/Admin/Auth/useVerifyAdmin";

const AdminLogin = () => {
  const navigate = useNavigate();
  const [level, setLevel] = useState(0);
  const [payload, setPayload] = useState({ id: "", otp: "" });

  const [email, setEmail] = useState("");
  const [disabled, setDisabled] = useState(true);
  const Logo = require("../../../assets/images/logotransparent.png");

  const { mutateAsync, isLoading } = useLoginAdmin();
  const { mutateAsync: verify, isLoading: isVerifying } = useVerifyAdmin();

  const handleSubmit = () => {
    mutateAsync(email, {
      onSuccess: (data) => {
        setLevel(1);
        setPayload({ ...payload, id: data?.data?.id });
      },
    });
  };

  const handleVerify = () => {
    verify(payload);
  };

  return (
    <div className="authscreen">
      <div>
        <div className="w-[500px] relative top-[50vh] left-[50vw] -translate-y-[50%] -translate-x-[50%] p-[40px] bg-white rounded-md shadow-md">
          <img src={Logo} alt="" className="h-12 mb-5" />

          <div>
            <span className="absolute text-purple900 font-bold -top-4 -right-4 bg-primary200 p-2 rounded-md rotate-[20deg] shadow-md">
              ADMIN
            </span>
          </div>
          {level === 0 && (
            <div>
              <label htmlFor="propertytype" className="font-gray">
                Email
              </label>
              <input
                name="email"
                type="email"
                placeholder="Email"
                value={email}
                className="px-6 py-4 my-3 rounded-md w-full cursor-pointer bg-slate-100"
                onChange={(e) => setEmail(e.target.value)}
              />

              <button
                type="submit"
                onClick={handleSubmit}
                disabled={!email}
                className={`px-4 block mx-auto w-full  text-white py-5 rounded-md  mt-4 ${
                  !email
                    ? "bg-primary300 cursor-not-allowed"
                    : "bg-primary800 cursor-pointer shadow-md"
                }`}
              >
                Login
              </button>
            </div>
          )}
          {level === 1 && (
            <div>
              :
              <label htmlFor="otp" className="font-gray">
                Email
              </label>
              <input
                name="otp"
                type="string"
                placeholder="OTP"
                value={payload?.otp}
                className="px-6 py-4 my-3 rounded-md w-full cursor-pointer bg-slate-100"
                onChange={(e) =>
                  setPayload({ ...payload, otp: e?.target?.value })
                }
              />
              <button
                type="submit"
                onClick={handleVerify}
                disabled={!payload?.id || !payload?.otp}
                className={`px-4 block mx-auto w-full  text-white py-5 rounded-md  mt-4 ${
                  !email
                    ? "bg-primary300 cursor-not-allowed"
                    : "bg-primary800 cursor-pointer shadow-md"
                }`}
              >
                Login
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;

{
  /* <label htmlFor="propertytype" className="font-gray">
            Password
          </label>
          <div className="relative">
            <input
              name="password"
              type={visibility === false ? "text" : "password"}
              placeholder="Password"
              className="px-6 py-4 my-3 rounded-md w-full cursor-pointer bg-slate-100"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />

            <span
              className="text-[24px] text-gray-500 cursor-pointer absolute right-2 top-[30px]"
              onClick={() => setVisibility(!visibility)}
            >
              {visibility === false ? (
                <AiOutlineEye />
              ) : (
                <AiOutlineEyeInvisible />
              )}
            </span>
          </div> */
}
